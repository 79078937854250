import React, { useState } from 'react';

const AddAddressDrawer = ({ open, onClose, onAddAddress }) => {
  const [formData, setFormData] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    pincode: '',
    state: '',
    label: 'Home',
  });

  const handleChange = (field) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onAddAddress(formData);
    onClose();
  };

  if (!open) return null;

  const drawerStyles = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white',
    boxShadow: '-2px 0 4px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    zIndex: 1000,
    overflowY: 'auto',
  };

  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  };

  const headerStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  };

  const closeButtonStyles = {
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    padding: '8px',
  };

  const formGroupStyles = {
    marginBottom: '20px',
  };

  const labelStyles = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '500',
    color: '#333',
  };

  const inputStyles = {
    width: '100%',
    padding: '12px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '14px',
  };

  const labelGroupStyles = {
    marginTop: '20px',
  };

  const labelButtonStyles = {
    padding: '10px 20px',
    borderRadius: '20px',
    border: 'none',
    marginRight: '12px',
    cursor: 'pointer',
    fontSize: '14px',
  };

  const activeLabelStyles = {
    ...labelButtonStyles,
    backgroundColor: '#F47458',
    color: 'white',
  };

  const inactiveLabelStyles = {
    ...labelButtonStyles,
    backgroundColor: '#F5F5F5',
    color: '#333',
  };

  const submitButtonStyles = {
    width: '100%',
    padding: '14px',
    backgroundColor: '#38447E',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '24px',
  };

  return (
    <>
      <div style={overlayStyles} onClick={onClose} />
      <div style={drawerStyles}>
        <div style={headerStyles}>
          <h2 style={{ margin: 0 }}>Add Address</h2>
          <button style={closeButtonStyles} onClick={onClose}>
            ×
          </button>
        </div>

        <div style={{ marginTop: '24px' }}>
          <h3 style={{ color: '#38447E', marginBottom: '20px' }}>Details</h3>

          <form onSubmit={handleSubmit}>
            <div style={formGroupStyles}>
              <label style={labelStyles} htmlFor="addressLine1">
                Address Line 1*
              </label>
              <input
                id="addressLine1"
                style={inputStyles}
                placeholder="Add flat no, area"
                value={formData.addressLine1}
                onChange={handleChange('addressLine1')}
                required
              />
            </div>

            <div style={formGroupStyles}>
              <label style={labelStyles} htmlFor="addressLine2">
                Address Line 2
              </label>
              <input
                id="addressLine2"
                style={inputStyles}
                placeholder="Add landmark"
                value={formData.addressLine2}
                onChange={handleChange('addressLine2')}
              />
            </div>

            <div style={{ display: 'flex', gap: '16px' }}>
              <div style={{ ...formGroupStyles, flex: 1 }}>
                <label style={labelStyles} htmlFor="city">
                  City*
                </label>
                <input
                  id="city"
                  style={inputStyles}
                  placeholder="Add City"
                  value={formData.city}
                  onChange={handleChange('city')}
                  required
                />
              </div>

              <div style={{ ...formGroupStyles, flex: 1 }}>
                <label style={labelStyles} htmlFor="pincode">
                  Pincode*
                </label>
                <input
                  id="pincode"
                  style={inputStyles}
                  placeholder="Add Pincode"
                  value={formData.pincode}
                  onChange={handleChange('pincode')}
                  required
                />
              </div>
            </div>

            <div style={formGroupStyles}>
              <label style={labelStyles} htmlFor="state">
                State*
              </label>
              <input
                id="state"
                style={inputStyles}
                placeholder="Add State"
                value={formData.state}
                onChange={handleChange('state')}
                required
              />
            </div>

            <div style={labelGroupStyles}>
              <label style={labelStyles}>Label</label>
              <div>
                <button
                  type="button"
                  style={formData.label === 'Home' ? activeLabelStyles : inactiveLabelStyles}
                  onClick={() => setFormData((prev) => ({ ...prev, label: 'Home' }))}
                >
                  Home
                </button>
                <button
                  type="button"
                  style={formData.label === 'Other' ? activeLabelStyles : inactiveLabelStyles}
                  onClick={() => setFormData((prev) => ({ ...prev, label: 'Other' }))}
                >
                  Other
                </button>
              </div>
            </div>

            <button type="submit" style={submitButtonStyles}>
              Add Address
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAddressDrawer;
