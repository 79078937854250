import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { AdminPanelHeader } from 'components';
import PatientInfoCard from './PatientInfoCard';
import BloodTestDetailsTable from './BloodTestDetailsTable';
import usePatientBloodReportDetails from '../../hooks/usePatientBloodReportDetails';
import { formatAddress } from './utils';
import { ToastContainer } from 'react-toastify';

const BloodTestDetails = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const { data: patientData, loading, error } = usePatientBloodReportDetails(patientId);

  const footerStyle = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    borderTop: '1px solid #E5E7EB',
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    zIndex: 100,
  };

  const buttonStyle = (isDisabled = false) => ({
    padding: '8px 16px',
    borderRadius: '6px',
    border: '1px solid #E5E7EB',
    backgroundColor: 'white',
    color: isDisabled ? '#9CA3AF' : '#111827',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    opacity: isDisabled ? 0.5 : 1,
  });

  const handlePrevious = () => {
    navigate(-1);
  };

  const handleViewReport = () => {
    if (patientData?.report) {
      window.open(patientData.report, '_blank');
    }
  };

  const handleDownloadReport = () => {
    if (patientData?.report) {
      const link = document.createElement('a');
      link.href = patientData.report;
      link.download = 'blood_test_report.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) {
    return (
      <div className="wrapper">
        <div className="wrapperContent" style={{ paddingInline: '2rem' }}>
          <div>Loading blood test details...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="wrapper">
        <div className="wrapperContent" style={{ paddingInline: '2rem' }}>
          <div>Error: {error}</div>
        </div>
      </div>
    );
  }

  if (!patientData) {
    return (
      <div className="wrapper">
        <div className="wrapperContent" style={{ paddingInline: '2rem' }}>
          <div>No data found for this blood test.</div>
        </div>
      </div>
    );
  }
  const user = patientData?.user;
  const address = patientData?.address;
  const formattedAddress = formatAddress(address);
  const patientCardData = {
    full_name: user?.full_name,
    phone: user?.phone,
    email: user?.email,
    address: formattedAddress,
  };
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="wrapperContent" style={{ paddingInline: '2rem', paddingBottom: '80px' }}>
        <div className="my-2" />

        <AdminPanelHeader
          title="Blood Test Details"
          showTree={[
            { title: 'Blood Tests', iconClass: 'science' },
            { title: patientData.user?.full_name || 'Patient' },
            { title: 'View Details' },
          ]}
        />
        <div className="line-separator" style={{ borderBottom: '1px solid #D9D9D9', transform: 'translateY(-18px)' }} />
        {/* Created Date */}
        <div style={{ color: '#666', marginBottom: '24px', fontSize: '14px' }}>
          Created On: {format(new Date(patientData.created_at || new Date()), 'dd MMM yyyy, hh:mm a')}
        </div>

        {/* Patient Info Card */}
        <div
          style={{
            background: '#F9FAFE',
            borderRadius: '8px',
            padding: '24px',
            marginBottom: '24px',
          }}
        >
          <PatientInfoCard patientData={patientCardData} />
        </div>

        {/* Blood Test Details */}
        <BloodTestDetailsTable patientData={patientData} />
        <div style={footerStyle}>
          <button style={buttonStyle()} onClick={handlePrevious}>
            Previous
          </button>
          <button style={buttonStyle(!patientData?.report)} onClick={handleViewReport} disabled={!patientData?.report}>
            View Report
          </button>
          <button
            style={buttonStyle(!patientData?.report)}
            onClick={handleDownloadReport}
            disabled={!patientData?.report}
          >
            Download Report
          </button>
        </div>
      </div>

      {/* Footer */}
    </div>
  );
};

export default BloodTestDetails;
