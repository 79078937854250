import React, { useState } from 'react';
import { Modal } from 'rsuite';
import { Dropdown } from 'rsuite';
import { dateFormat, timeFormat, titlizeString, profileImage } from '../Helper';
import PatientPrescription from '../Orders/PatientPrescription';
import { location, noteSave } from 'res/images';
import CoreInputBox from '../CoreInputBox';
import { checkCircle, cancelCircle, checkCircleInactive, cancelCircleInactive } from '../../assets/images';
import CoreButton from '../CoreButton';
import Cancel1MGOrder from './Cancel1MGOrder';
import ViewOrderDrawer from './ViewOrderDrawer';
import auth_axios from 'networking/AuthAxios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const OrderDetails = ({
  item,
  index,
  orderSummary,
  patientApiDetails,
  isEditMode,
  setEditMode,
  fulfilledItemErrMsg,
  renderOrderStatusDropdown,
  showEditIcon,
  pendingItemErrorMsg,
  updateOrderErrMsg,
  isLoading,
  screenLoading,
  orderLoadingState,
  vendorValueArray,
  venderOptions,
  setVendorValueArray,
  orderQuantity,
  setOrderQuantity,
  errorMsgToBeFull,
  setErrorMsgToBeFull,
  updateOrder,
  setUpdateClicked,
  isEditable,
  setIsEditable,
  orderStatusArray,
  notes,
  handleNoteChange,
  handleSaveNotes,
  notesArr,
  reduxNotesData,
  patientDetails,
  userData,
  formatDate,
  orderStatusWithColor,
  setVendorOrder,
  setPayoutData,
  orderId,
  patientId,
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showViewDrawer, setShowViewDrawer] = useState(false);
  const [oneMgOrderDetails, setOneMgOrderDetails] = useState(null);
  const [isViewOrderLoading, setIsViewOrderLoading] = useState(false);
  const [isViewOrderLoadingForPendingItems, setIsViewOrderLoadingForPendingItems] = useState(false);
  const handleCancelOrder = (order) => {
    setSelectedOrder(order);
    setShowCancelModal(true);
  };
  const navigate = useNavigate();

  const handleConfirmCancel = async () => {
    try {
      setShowCancelModal(false);
      // Refresh the order data after successful cancellation
      window.location.reload(); // This is a temporary solution - ideally we should have a proper refresh mechanism
    } catch (error) {
      console.error('Error cancelling order:', error);
    } finally {
      setSelectedOrder(null);
    }
  };
  const handleView1MGOrder = async () => {
    try {
      setIsViewOrderLoading(true);
      const response = await auth_axios.get(
        `/manual_order/get_one_mg_order_details?order_id=${item?.order_details?.[0]?.order}`
      );

      if (response.data?.success === false) {
        toast.error(response.data?.message || 'Failed to fetch 1MG order details');
        return;
      }

      setOneMgOrderDetails(response.data);
      setShowViewDrawer(true);
    } catch (error) {
      console.error('Error fetching 1MG order details:', error);
      toast.error(error.response?.data?.message || 'Failed to fetch 1MG order details');
    } finally {
      setIsViewOrderLoading(false);
    }
  };
  const handleView1MGOrderForPendingItems = async () => {
    try {
      setIsViewOrderLoadingForPendingItems(true);
      const response = await auth_axios.get(
        `/manual_order/get_one_mg_order_details?order_id=${item?.order_details?.[0]?.order}`
      );

      if (response.data?.success === false) {
        toast.error(response.data?.message || 'Failed to fetch 1MG order details');
        return;
      }

      setOneMgOrderDetails(response.data);
      setShowViewDrawer(true);
    } catch (error) {
      console.error('Error fetching 1MG order details:', error);
      toast.error(error.response?.data?.message || 'Failed to fetch 1MG order details');
    } finally {
      setIsViewOrderLoadingForPendingItems(false);
    }
  };

  const cancelOrderAndViewButton = (item2, type) => {
    return (
      <>
        <div
          onClick={() => handleCancelOrder(item2)}
          className="reusableBtnActive reusableBtn"
          style={{
            background: '#fff',
            border: '1px solid red',
            color: 'red',
            cursor: 'pointer',
          }}
        >
          Cancel Order
        </div>
        <div
          onClick={type === 'normal' ? handleView1MGOrder : handleView1MGOrderForPendingItems}
          className="reusableBtnActive reusableBtn"
          style={{
            background: '#fff',
            border: '1px solid #133696',
            color: '#133696',
            cursor:
              type === 'normal'
                ? isViewOrderLoading
                  ? 'not-allowed'
                  : 'pointer'
                : isViewOrderLoadingForPendingItems
                ? 'not-allowed'
                : 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            opacity: isViewOrderLoading ? 0.7 : 1,
          }}
        >
          {isViewOrderLoading ? (
            <>
              <div className="button-loader"></div>
              Loading...
            </>
          ) : (
            'View Order'
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ marginTop: '5px' }}>
        <span
          style={{
            fontSize: '12px',
            color: '#848484',
            fontWeight: '500',
            textDecorationColor: '#757575',
          }}
        >
          Created On: {dateFormat(item?.created_at)}, {timeFormat(item?.created_at)}
        </span>
      </div>

      {/* Order Header */}
      <div className="d-flex justify-content-between" style={{ width: '100%' }}>
        <p style={{ position: 'absolute' }}>{item?.ehr_order_status && orderStatusWithColor(item, index, true)}</p>
        <div className="d-flex flex-col justify-content-between items-center space-x-4 order-modal">
          <div
            className="d-flex align-items-center"
            style={{
              background: '#F9FAFE',
              padding: '10px',
              borderRadius: '8px',
              width: '50%',
            }}
          >
            <div className="mr-2 mt-4 text-capitalize" style={{ fontWeight: '500' }}>
              <p>Source: {orderSummary.source}</p>
              <p>Order ID : {item?.order_id}</p>
            </div>
            <div className="vl">.</div>
            <div className="mx-2 patient-name" style={{ fontWeight: 'bold', color: '#243b86' }}>
              <img
                className="listUserImage rounded-circle"
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
                src={profileImage(patientApiDetails?.gender, patientApiDetails?.profile_url)}
              />
              {patientApiDetails?.full_name}
            </div>
            <div className="mx-2 patient-address d-flex">
              <div>
                <img src={location} alt="location" />
              </div>
              <div className="complete-address" style={{ fontWeight: '500' }}>
                {item?.shipping_address}
              </div>
            </div>
          </div>
          <div style={{ marginRight: '20px' }}>
            <div
              onClick={() => setEditMode(true)}
              className="reusableBtnActive reusableBtn"
              style={{
                background: '#fff',
                border: '1px solid #133696',
                color: '#133696',
              }}
            >
              View Prescription
            </div>
            <Modal backdrop="static" size="md" open={isEditMode} onClose={() => setEditMode(false)}>
              <Modal.Header>
                <div className="settingFormOutline p-0">
                  <div className="formTitle mb-0">Prescription</div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <PatientPrescription patientId={item?.user?.id} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>

      {/* Fulfilled Orders */}
      {item?.fulfilled_order?.map((item2, index2) => (
        <div key={index2} className="diet-regime cardDataBorder w-100 patientordertabtable fulfilled-suborder">
          {fulfilledItemErrMsg[index2] && <span className="requiredInput">{fulfilledItemErrMsg[index2]}</span>}
          <div
            className="table-suborder"
            style={item2?.vendor?.vendor_name === 'WareIQ' ? { overflowX: 'scroll' } : {}}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="table-heading d-flex justify-content-between align-items-center mb-3 gap-2">
                <div className="d-flex">
                  <div>
                    <b>
                      Sub-Order {index2 + 1}:{' '}
                      {item2?.vendor?.vendor_logo && (
                        <img
                          src={item2?.vendor?.vendor_logo}
                          height="15px"
                          width="15px"
                          style={{
                            margin: '-2px 4px 0 3px',
                            objectFit: 'contain',
                          }}
                        />
                      )}
                      {item2?.vendor?.vendor_name}
                    </b>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  {item2?.vendor?.vendor_name?.toLowerCase() === '1mg' && cancelOrderAndViewButton(item2, 'normal')}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10, padding: '20px' }}>
                {item2?.order_items && item2?.order_items.length > 0 && item2?.order_items[0]?.payout_id !== null && (
                  <div
                    onClick={() => {
                      navigate(`/orders/${orderId}/${patientId}/view-payout/${item2?.order_items[0]?.payout_id}`);
                    }}
                    className="reusableBtnActive reusableBtn"
                    style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                  >
                    View Payout
                  </div>
                )}
                {item2?.order_items &&
                  item2?.order_items.length > 0 &&
                  item2?.order_items[0]?.invoice_id &&
                  !(
                    item2?.order_items &&
                    item2?.order_items.length > 0 &&
                    item2?.order_items[0]?.payout_id !== null
                  ) && (
                    <div
                      onClick={() => {
                        setPayoutData({
                          invoiceId: item2?.order_items[0]?.invoice_id,
                          vendorName: item2?.vendor?.vendor_name,
                        });
                      }}
                      className="reusableBtnActive reusableBtn"
                      style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                      data-bs-toggle={'offcanvas'}
                      data-bs-target={'#payoutModal'}
                    >
                      Generate Payout
                    </div>
                  )}

                {item2?.order_items && item2?.order_items.length > 0 && item2?.order_items[0]?.invoice_id && (
                  <div
                    onClick={() => {
                      navigate(`/orders/${orderId}/${patientId}/view-invoice/${item2?.vendor?.id}`);
                    }}
                    className="reusableBtnActive reusableBtn"
                    style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                  >
                    View Invoice
                  </div>
                )}

                {item2?.order_items &&
                  item2?.order_items.length > 0 &&
                  item2?.vendor?.vendor_name !== '1MG' &&
                  !item2?.order_items[0]?.invoice_id && (
                    <div
                      onClick={() => {
                        setVendorOrder({
                          vendorOrderDetails: item2,
                          orderId: item.id,
                        });
                      }}
                      className="reusableBtnActive reusableBtn"
                      style={{ background: '#fff', border: '1px solid #133696', color: '#133696' }}
                      data-bs-toggle={'offcanvas'}
                      data-bs-target={'#invoiceModal'}
                    >
                      Generate Invoice
                    </div>
                  )}
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  {item2?.vendor?.vendor_name === 'WareIQ' && <th className="w-100">Invoice Id</th>}
                  {item2?.vendor?.vendor_name === 'WareIQ' && <th className="w-100">Ware IQ Id</th>}
                  <th>Medicine</th>
                  <th>SKU</th>
                  <th>Vendor</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Disc. Price</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {item2?.order_items?.map((medicineItem, medicineIndex) => {
                  const errorMsgIndex = `${index}-${medicineIndex}`;
                  const defaultVendor = medicineItem?.vendor;
                  const defaultVendorPrice = medicineItem?.product_item?.product_price?.find(
                    (price) => price.vendor?.id === defaultVendor?.id
                  );
                  const elevateNowVendor = medicineItem?.product_item?.product_price?.find(
                    (item) => item?.vendor?.vendor_name === 'ElevateNow'
                  );

                  return (
                    <tr key={medicineIndex}>
                      <td>
                        <b>{medicineIndex + 1}</b>
                      </td>
                      {item2?.vendor?.vendor_name === 'WareIQ' && (
                        <td style={{ minWidth: '100px' }}>
                          <b>{medicineItem?.invoice_id || 'N/A'}</b>
                        </td>
                      )}
                      {item2?.vendor?.vendor_name === 'WareIQ' && (
                        <td style={{ minWidth: '100px' }}>
                          <b>{medicineItem?.wiq_order_id || 'N/A'}</b>
                        </td>
                      )}
                      <td className="capitalize">
                        <b>
                          {medicineItem?.product_item?.product?.medicine?.formulation}
                          &nbsp;
                          {medicineItem.product_item.product.product_name}
                        </b>
                      </td>
                      <td style={{ minWidth: '170px' }}>
                        <b>{defaultVendorPrice?.sku || medicineItem?.product_item?.sku || elevateNowVendor?.sku}</b>
                      </td>
                      <td>{medicineItem?.vendor?.vendor_name}</td>
                      <td>
                        <div className="w-60" style={{ width: '60px' }}>
                          {item.fulfilled_order[index2].order_items[medicineIndex].fulfilled_qty}
                        </div>
                      </td>
                      <td>Rs.{parseFloat(medicineItem?.item_mrp).toFixed(2)}</td>
                      <td>Rs.{parseFloat(medicineItem?.item_amount).toFixed(2)}</td>
                      <td>
                        Rs.
                        {parseFloat(medicineItem.fulfilled_qty * medicineItem.item_amount).toFixed(2)}
                      </td>
                      <td>{renderOrderStatusDropdown(medicineItem, medicineIndex, true, index2)}</td>
                      <td style={{ minWidth: '75px' }}>
                        {medicineItem.item_status !== 'delivered' ? showEditIcon(index, index2, medicineIndex) : ''}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ))}

      {/* Unfulfilled Orders */}
      {item?.unfulfilled_order?.length > 0 && <div className="mt-3">Unfulfilled Orders</div>}
      {item?.unfulfilled_order?.map((item2, index2) => (
        <div key={index2} className="diet-regime cardDataBorder w-100">
          <table className="table table-suborder" style={{ border: '1px solid #D2D2D2', borderRadius: '5px' }}>
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Medicine</th>
                <th>SKU</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Disc. Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {item2?.order_items?.map((medicineItem, medicineIndex) => {
                const errorMsgIndex = `${index}-${medicineIndex}`;
                const elevateNowVendor = medicineItem?.product_item?.product_price?.find(
                  (item) => item?.vendor?.vendor_name === 'ElevateNow'
                );
                return (
                  <tr key={medicineIndex}>
                    <td>
                      <b>{medicineIndex + 1}</b>
                    </td>
                    <td className="capitalize">
                      <b>
                        {medicineItem?.product_item?.product?.medicine?.formulation}
                        &nbsp;
                        {medicineItem.product_item.product.product_name}
                      </b>
                    </td>
                    <td>
                      <b>{elevateNowVendor?.sku}</b>
                    </td>
                    <td>{medicineItem.quantity}</td>
                    <td>Rs. {medicineItem?.item_mrp}</td>
                    <td>Rs. {medicineItem?.item_amount}</td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <span
                          className={`status-text align-items-center badge capitalize active align-items-center d-flex unfulfilled-status ${
                            medicineItem?.item_status === 'cancelled' && medicineItem?.item_status
                          }`}
                        >
                          {medicineItem?.item_status === 'cancelled' ? 'Cancelled' : 'Unfulfilled'}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}

      {/* Pending Items */}
      {item?.tobefullfilled_orders?.length > 0 && (
        <div className="mt-3">
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div>Pending Items</div>
          </div>
        </div>
      )}

      {(pendingItemErrorMsg || updateOrderErrMsg) && (
        <span className="requiredInput">{pendingItemErrorMsg || updateOrderErrMsg}</span>
      )}
      {item?.tobefullfilled_orders?.map((item2, index2) => {
        const allItemsAreFromElevateNow = item2?.order_items?.every(
          (item) => item?.vendor?.vendor_name?.toLowerCase() === 'elevatenow'
        );

        return (
          <div key={index2} className="diet-regime cardDataBorder patientordertabtable w-95">
            <div className="d-flex gap-2 mb-2">
              {!allItemsAreFromElevateNow && cancelOrderAndViewButton(item2, 'pending')}
            </div>
            <table
              className="table"
              style={{
                width: '100% !important',
                borderTop: '1px solid #E7E7E7',
                borderBottom: '1px solid #E7E7E7',
                borderRadius: '5px',
              }}
            >
              <thead>
                {item.order_status === 'draft' && (
                  <tr style={{ borderBottom: '1px solid black' }}>
                    <th
                      style={{
                        paddingBottom: 8,
                        textAlign: 'left',
                        width: 100,
                        background: '#D9D9D940',
                      }}
                    >
                      Draft Order
                    </th>
                  </tr>
                )}
                <tr>
                  <th>Sr.No.</th>
                  <th>Medicine</th>
                  <th>SKU</th>
                  <th>Vendor</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Disc. Price</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {item2?.order_items?.map((medicineItem, medicineIndex) => {
                  const errorMsgIndex = `${index}-${medicineIndex}`;
                  const defaultVendor = medicineItem?.vendor;
                  const defaultVendorPrice = medicineItem?.product_item?.product_price?.find(
                    (price) => price.vendor?.id === defaultVendor?.id
                  );
                  return (
                    <tr key={medicineIndex}>
                      <td>
                        <b>{medicineIndex + 1}</b>
                      </td>
                      <td className="capitalize">
                        <b>
                          {medicineItem?.product_item?.product?.medicine?.formulation}
                          &nbsp;
                          {medicineItem.product_item.product.product_name}
                        </b>
                      </td>
                      <td>
                        <b>{defaultVendorPrice?.sku || medicineItem?.product_item?.sku}</b>
                      </td>
                      <td>
                        {medicineItem?.vendor?.vendor_name === '1MG' ? (
                          <b>{medicineItem?.vendor?.vendor_name}</b>
                        ) : (
                          <Dropdown className="capitalize pointer" title={vendorValueArray[medicineIndex] || 'Select'}>
                            {venderOptions?.map((item, index3) => (
                              <Dropdown.Item
                                key={index3}
                                onClick={() => {
                                  setVendorValueArray((prevVendorValueArray) => {
                                    const updatedArray = [...prevVendorValueArray];
                                    updatedArray[medicineIndex] = item.vendor_name;
                                    return updatedArray;
                                  });
                                }}
                              >
                                {item?.vendor_logo && (
                                  <img
                                    src={item.vendor_logo}
                                    height="15px"
                                    width="15px"
                                    style={{
                                      margin: '-2px 4px 0 3px',
                                      objectFit: 'contain',
                                    }}
                                    alt={item.vendor_name}
                                  />
                                )}{' '}
                                {item?.vendor_name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown>
                        )}
                      </td>
                      <td>
                        <div
                          className="w-60"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <button
                              disabled={medicineItem?.vendor?.vendor_name === '1MG'}
                              onClick={(e) => {
                                let updatedQuantity =
                                  (orderQuantity[medicineIndex] != undefined
                                    ? orderQuantity[medicineIndex]
                                    : item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]
                                        ?.to_beordered_quantity) - 1;
                                setOrderQuantity((prevVendorValueArray) => {
                                  const updatedOrderStatusArray = [...prevVendorValueArray];
                                  updatedOrderStatusArray[medicineIndex] = updatedQuantity;
                                  prevVendorValueArray[medicineIndex] = updatedQuantity;
                                  return updatedOrderStatusArray;
                                });
                                const originalQty = medicineItem.quantity;
                                if (updatedQuantity > 0 && updatedQuantity <= originalQty) {
                                  setErrorMsgToBeFull((prevErrorMsg) => {
                                    const updatedErrorMsg = { ...prevErrorMsg };
                                    updatedErrorMsg[medicineIndex] = '';
                                    return updatedErrorMsg;
                                  });
                                } else if (updatedQuantity <= 0) {
                                  setErrorMsgToBeFull((prevErrorMsg) => {
                                    const updatedErrorMsg = { ...prevErrorMsg };
                                    updatedErrorMsg[medicineIndex] =
                                      'Invalid quantity. Please enter a value greater than 0';
                                    return updatedErrorMsg;
                                  });
                                } else {
                                  setErrorMsgToBeFull((prevErrorMsg) => {
                                    const updatedErrorMsg = { ...prevErrorMsg };
                                    updatedErrorMsg[medicineIndex] =
                                      'Invalid quantity. Please enter a value less than or equal to the original quantity.';
                                    return updatedErrorMsg;
                                  });
                                }
                              }}
                              style={{
                                padding: '0px 10px 0 10px',
                                height: '34px',
                                borderRadius: '5px',
                                marginRight: '-4px',
                                zIndex: 2,
                                backgroundColor: '#193990',
                                color: '#fff',
                              }}
                            >
                              -
                            </button>
                            <div
                              style={{
                                width: '50px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <CoreInputBox
                                disabled={true}
                                inputStyle={{
                                  borderRaius: '0 !important',
                                  textAlign: 'center',
                                }}
                                showMSG={errorMsgToBeFull[medicineIndex]}
                                value={
                                  orderQuantity[medicineIndex] != undefined
                                    ? orderQuantity[medicineIndex]
                                    : item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]
                                        ?.to_beordered_quantity
                                }
                                setValue={(data) => {
                                  const updatedQty = parseInt(data, 10) || 0;
                                  const originalQty = medicineItem.quantity;
                                  setOrderQuantity((prevVendorValueArray) => {
                                    const updatedOrderStatusArray = [...prevVendorValueArray];
                                    updatedOrderStatusArray[medicineIndex] = updatedQty;
                                    prevVendorValueArray[medicineIndex] = updatedQty;
                                    return updatedOrderStatusArray;
                                  });
                                  if (updatedQty <= originalQty && updatedQty > 0) {
                                    setErrorMsgToBeFull((prevErrorMsg) => {
                                      const updatedErrorMsg = {
                                        ...prevErrorMsg,
                                      };
                                      updatedErrorMsg[medicineIndex] = '';
                                      return updatedErrorMsg;
                                    });
                                  } else {
                                    setErrorMsgToBeFull((prevErrorMsg) => {
                                      const updatedErrorMsg = {
                                        ...prevErrorMsg,
                                      };
                                      updatedErrorMsg[medicineIndex] =
                                        'Invalid quantity. Please enter a value less than or equal to the original quantity.';
                                      return updatedErrorMsg;
                                    });
                                  }
                                }}
                              />
                            </div>
                            <button
                              disabled={medicineItem?.vendor?.vendor_name === '1MG'}
                              onClick={(e) => {
                                let updatedQuantity =
                                  (orderQuantity[medicineIndex] != undefined
                                    ? orderQuantity[medicineIndex]
                                    : item?.tobefullfilled_orders[index2]?.order_items[medicineIndex]
                                        ?.to_beordered_quantity) + 1;
                                setOrderQuantity((prevVendorValueArray) => {
                                  const updatedOrderStatusArray = [...prevVendorValueArray];
                                  updatedOrderStatusArray[medicineIndex] = updatedQuantity;
                                  prevVendorValueArray[medicineIndex] = updatedQuantity;
                                  return updatedOrderStatusArray;
                                });
                                const originalQty = medicineItem.quantity;
                                if (updatedQuantity <= originalQty && updatedQuantity > 0) {
                                  setErrorMsgToBeFull((prevErrorMsg) => {
                                    const updatedErrorMsg = { ...prevErrorMsg };
                                    updatedErrorMsg[medicineIndex] = '';
                                    return updatedErrorMsg;
                                  });
                                } else {
                                  setErrorMsgToBeFull((prevErrorMsg) => {
                                    const updatedErrorMsg = { ...prevErrorMsg };
                                    updatedErrorMsg[medicineIndex] =
                                      'Invalid quantity. Please enter a value less than or equal to the original quantity.';
                                    return updatedErrorMsg;
                                  });
                                }
                              }}
                              style={{
                                padding: '0px 10px 0 10px',
                                height: '34px',
                                borderRadius: '5px',
                                marginLeft: '-4px',
                                zIndex: 2,
                                backgroundColor: '#919191',
                                color: '#fff',
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>Rs.{parseFloat(medicineItem?.item_mrp).toFixed(2)}</td>
                      <td>Rs.{parseFloat(medicineItem?.item_amount).toFixed(2)}</td>
                      <td>
                        Rs.
                        {(parseFloat(medicineItem.fulfilled_qty) * parseFloat(medicineItem?.item_amount)).toFixed(2)}
                      </td>
                      <td
                        style={{
                          pointerEvents: medicineItem?.vendor?.vendor_name === '1MG' ? 'none' : 'auto',
                        }}
                      >
                        {isLoading || (screenLoading && orderLoadingState === medicineIndex) ? (
                          <div
                            style={{
                              borderRadius: 8,
                              padding: 2,
                              fontSize: '10px',
                            }}
                          >
                            Updating
                          </div>
                        ) : (
                          renderOrderStatusDropdown(medicineItem, medicineIndex)
                        )}
                      </td>
                      <td>
                        <>
                          <button
                            style={{ backgroundColor: '#fff' }}
                            onClick={() => {
                              updateOrder(index, medicineIndex);
                              setUpdateClicked(true);
                            }}
                            disabled={
                              !vendorValueArray[medicineIndex] &&
                              orderStatusArray[medicineIndex] !== 'cancelled' &&
                              orderStatusArray[medicineIndex] === undefined
                            }
                          >
                            {orderStatusArray[medicineIndex] === 'cancelled' ? (
                              <img src={checkCircle} className="pointer" />
                            ) : orderStatusArray[medicineIndex] === undefined ? (
                              <img src={checkCircleInactive} className="pointer" />
                            ) : (
                              <img src={checkCircle} className="pointer" />
                            )}
                          </button>{' '}
                          <button
                            style={{ backgroundColor: '#fff' }}
                            onClick={() => {
                              isEditable &&
                                setIsEditable((isEditable) => {
                                  const updatedOrderStatusArray = [...isEditable];
                                  updatedOrderStatusArray[medicineIndex] = !isEditable[medicineIndex];
                                  return updatedOrderStatusArray;
                                });
                              setUpdateClicked(false);
                              setVendorValueArray((prevVendorValueArray) => {
                                const updatedOrderVendorArray = [...prevVendorValueArray];
                                updatedOrderVendorArray[medicineIndex] = null;
                                prevVendorValueArray[medicineIndex] = null;
                                return updatedOrderVendorArray;
                              });
                            }}
                            disabled={
                              !vendorValueArray[medicineIndex] &&
                              orderStatusArray[medicineIndex] !== 'cancelled' &&
                              orderStatusArray[medicineIndex] === undefined
                            }
                          >
                            {orderStatusArray[medicineIndex] === 'cancelled' ? (
                              <img src={cancelCircle} className="pointer" />
                            ) : orderStatusArray[medicineIndex] === undefined ? (
                              <img src={cancelCircleInactive} className="pointer" />
                            ) : (
                              <img src={cancelCircle} className="pointer" />
                            )}
                          </button>{' '}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}

      {/* Order Summary */}
      <div style={{ marginRight: '0px' }} className="row justify-content-end">
        <div className="col-lg-4">
          <div className="order-summary p-3 bg-light rounded" style={{ marginRight: '50px' }}>
            <h6 className="fw-bold mb-3">Order Summary</h6>
            <div className="d-flex justify-content-between mb-2">
              <div>Order Total</div>
              <div>Rs. {orderSummary?.order_total?.toFixed(2)}</div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div>Discount</div>
              <div>
                -Rs. {orderSummary.discount} {`(${orderSummary?.discount_percentage}%)`}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div>Shipping</div>
              <div>{orderSummary.shipping_amount == '0' ? 'Free' : orderSummary.shipping_amount}</div>
            </div>
            <div className="hrLine mb-3" />
            <div className="d-flex justify-content-between fw-bold">
              <div>Total</div>
              <div>Rs. {orderSummary?.order_paid_amount}</div>
            </div>
            <div className="hrLine mb-3" />
          </div>
        </div>
      </div>

      {/* Notes Section */}
      <div className="hrLine mb-3" style={{ background: '#D1DCFF', marginRight: '50px' }} />
      <div className="row">
        <div className="col-lg-12">
          <div className="notes-section mt-3">
            <label className="fw-bold">Notes</label>
            <div className="textarea-container">
              <textarea value={notes} onChange={handleNoteChange} className="form-control mb-3" />
              <button className="save-button" onClick={handleSaveNotes}>
                <img src={noteSave} alt="Save Note" />
              </button>
            </div>
            {notesArr.length > 0 ? (
              notesArr
                .slice()
                .reverse()
                .map((note) => (
                  <div key={note?.id} className="note-container mt-2">
                    <div className="mr-2" style={{ marginRight: '10px' }}>
                      <img
                        src={profileImage(patientDetails.gender, userData?.profile_url)}
                        style={{ height: 40 }}
                        alt="Profile"
                      />
                    </div>
                    <div className="note">
                      <div className="note-time">{formatDate(note?.updated_at)}</div>
                      <div className="note-content">{note?.content}</div>
                    </div>
                  </div>
                ))
            ) : reduxNotesData.length > 0 ? (
              reduxNotesData
                .slice()
                .reverse()
                .map((note) => (
                  <div key={note?.id} className="note-container mt-2">
                    <div className="mr-2" style={{ marginRight: '10px' }}>
                      <img
                        src={profileImage(patientDetails.gender, userData?.profile_url)}
                        style={{ height: 40 }}
                        alt="Profile"
                      />
                    </div>
                    <div className="note">
                      <div className="note-time">{formatDate(note?.updated_at)}</div>
                      <div className="note-content">{note?.content}</div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="note-container">
                <div className="mr-2" style={{ marginRight: '10px' }}>
                  <img
                    src={profileImage(patientDetails.gender, userData?.profile_url)}
                    style={{ height: 40 }}
                    alt="Avatar"
                  />
                </div>
                <div className="note" style={{ height: '40px' }}>
                  <div className="note-time"></div>
                  <div className="note-content"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add ViewOrderDrawer component */}
      <ViewOrderDrawer
        isOpen={showViewDrawer}
        onClose={() => setShowViewDrawer(false)}
        orderDetails={oneMgOrderDetails}
      />

      {/* Existing Cancel1MGOrder Modal */}
      <Cancel1MGOrder
        isOpen={showCancelModal}
        onClose={() => {
          setShowCancelModal(false);
          setSelectedOrder(null);
        }}
        onConfirm={handleConfirmCancel}
        orderDetails={
          selectedOrder
            ? {
                id: selectedOrder?.order_items?.[0]?.order,
              }
            : null
        }
      />

      <style jsx>{`
        .button-loader {
          border: 2px solid #f3f3f3;
          border-radius: 50%;
          border-top: 2px solid #133696;
          width: 16px;
          height: 16px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default OrderDetails;
