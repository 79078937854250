import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import useServiceAvailability from '../../hooks/useServiceAvailability';
import useScheduleBloodTest from '../../hooks/useScheduleBloodTest';
import { formatTimeSafe } from './utils';
import useAddressCoordinates from 'whealth-core-web/hooks/useAddressCoordinates';
import { toast, ToastContainer } from 'react-toastify';

const EditBloodTestDrawer = ({ open, onClose, patientData, callback }) => {
  const [date, setDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const {
    coordinates: geodata,
    isLoading: isLoadingCoordinates,
    setCoordinates: setGeodata,
  } = useAddressCoordinates(patientData?.address);

  const {
    data,
    isChecking,
    isLoadingSlots,
    error: availabilityError,
    slots,
  } = useServiceAvailability({
    date,
    geodata,
  });

  const { scheduleTest, rescheduleTest, isScheduling, error: scheduleError } = useScheduleBloodTest();

  const isServiceable = data?.is_serviceable;
  const provider = data?.provider;

  const containerStyle = {
    zIndex: 100,
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: '400px',
    backgroundColor: 'white',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    padding: '24px',
    display: open ? 'block' : 'none',
    overflowY: 'auto',
    paddingBottom: '100px',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  };

  const closeButtonStyle = {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: '8px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '500',
  };

  const dateContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '16px',
  };

  const dateButtonStyle = (isSelected) => ({
    padding: '10px',
    borderRadius: '12px',
    backgroundColor: isSelected ? '#FF7043' : '#F5F5F5',
    color: isSelected ? 'white' : 'black',
    cursor: 'pointer',
    border: 'none',
    width: '50px',
    textAlign: 'center',
  });

  const slotsContainerStyle = {
    display: 'flex',
    marginTop: '16px',
    gap: '10px',
    flexWrap: 'wrap',
  };

  const slotButtonStyle = (isSelected) => ({
    padding: '10px',
    borderRadius: '12px',
    backgroundColor: isSelected ? '#FF7043' : '#F5F5F5',
    color: isSelected ? 'white' : 'black',
    cursor: 'pointer',
    border: 'none',
    width: '60px',
    textAlign: 'center',
  });

  const buttonStyle = {
    width: '100%',
    padding: '14px',
    backgroundColor: '#243B86',
    color: 'white',
    border: 'none',
    borderRadius: '12px',
    marginTop: '24px',
    cursor: 'pointer',
    opacity: !date || !selectedSlot || isChecking || isLoadingSlots || !isServiceable ? 0.5 : 1,
  };
  console.log('selectedSlot', selectedSlot);
  const handleReschedule = async () => {
    try {
      const rescheduleResponse = await rescheduleTest({
        collectionDate: date,
        collectionSlot: selectedSlot,
        recordId: patientData.id,
        slotId: selectedSlot?.id,
        bookingId: patientData?.booking_id,
      });

      toast.success(
        `Blood test ${rescheduleResponse?.booking_id} at ${selectedSlot?.slot_datetime} rescheduled successfully`
      );
      const t = setTimeout(() => {
        callback?.();
        onClose();
        clearTimeout(t);
      }, 500);
      return () => clearTimeout(t);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.error('Failed to reschedule test:', err);
    }
  };

  return (
    <div style={containerStyle}>
      <ToastContainer />
      <div style={headerStyle}>
        <h2 style={{ margin: 0, fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>Reschedule Blood Test</h2>
        <button style={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <label style={labelStyle}>Location Coordinates</label>
        <div style={{ display: 'flex', gap: '16px' }}>
          <div style={{ flex: 1 }}>
            <label style={{ fontSize: '14px', color: '#374151', marginBottom: '4px', display: 'block' }}>
              Latitude
            </label>
            <input
              type="text"
              placeholder="Latitude"
              value={geodata?.latitude}
              onChange={(e) => setGeodata((prev) => ({ ...prev, latitude: e.target.value }))}
              style={{
                width: '100%',
                padding: '8px 12px',
                border: '1px solid #E5E7EB',
                borderRadius: '8px',
                fontSize: '14px',
              }}
              disabled={isLoadingCoordinates}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ fontSize: '14px', color: '#374151', marginBottom: '4px', display: 'block' }}>
              Longitude
            </label>
            <input
              type="text"
              placeholder="Longitude"
              value={geodata?.longitude}
              onChange={(e) => setGeodata((prev) => ({ ...prev, longitude: e.target.value }))}
              style={{
                width: '100%',
                padding: '8px 12px',
                border: '1px solid #E5E7EB',
                borderRadius: '8px',
                fontSize: '14px',
              }}
              disabled={isLoadingCoordinates}
            />
          </div>
        </div>
        {isLoadingCoordinates && (
          <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>Getting coordinates from address...</div>
        )}
      </div>
      <div>
        <label style={labelStyle}>Select a date</label>
        <div style={dateContainerStyle}>
          {[...Array(5)].map((_, index) => {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + index);
            return (
              <button
                key={index}
                onClick={() => setDate(currentDate)}
                style={dateButtonStyle(date?.toDateString() === currentDate.toDateString())}
              >
                {currentDate.getDate()}
                <br />
                {currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
              </button>
            );
          })}
        </div>

        {isChecking && <p style={{ color: '#1976d2' }}>Checking availability...</p>}

        {!isChecking && isServiceable && provider && (
          <div className="my-4">
            <label style={labelStyle}>Available Vendor</label>
            <div style={{ padding: '12px', backgroundColor: '#f0f0f0', borderRadius: '8px', color: '#222' }}>
              {provider}
            </div>
          </div>
        )}

        {isLoadingSlots && <p style={{ color: '#1976d2' }}>Loading available slots...</p>}

        {!isChecking && !isLoadingSlots && isServiceable && slots.length > 0 && (
          <>
            <label style={labelStyle}>Available Time</label>
            <div style={slotsContainerStyle}>
              {slots.map((slot) => {
                const slotTime = formatTimeSafe(slot?.slot_datetime);

                return (
                  <button
                    key={slotTime}
                    onClick={() => setSelectedSlot(slot)}
                    style={slotButtonStyle(selectedSlot?.slot_datetime === slot?.slot_datetime)}
                    // disabled={!slot.is_available}
                  >
                    {slotTime}
                  </button>
                );
              })}
            </div>
          </>
        )}

        {!isChecking && isServiceable === false && (
          <p style={{ color: 'red' }}>Sorry, service is not available in this area for the selected date.</p>
        )}

        {availabilityError && <p style={{ color: 'red' }}>{availabilityError}</p>}

        {scheduleError && <p style={{ color: 'red' }}>{scheduleError}</p>}

        <button
          style={buttonStyle}
          onClick={handleReschedule}
          disabled={!date || !selectedSlot || isChecking || isLoadingSlots || !isServiceable || isScheduling}
        >
          {isScheduling ? 'Rescheduling...' : 'Reschedule'}
        </button>
      </div>
    </div>
  );
};

EditBloodTestDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    full_name: PropTypes.string,
    pincode: PropTypes.string,
    address_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gender: PropTypes.oneOf(['male', 'female', 'other']),
  }),
};

export default EditBloodTestDrawer;
