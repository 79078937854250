import { useState, useEffect } from 'react';
import auth_axios from 'networking/AuthAxios';

const useVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchVendors = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await auth_axios.get('/vendors');
      setVendors(response.data);
    } catch (err) {
      setError(err?.response?.data?.message || 'Failed to fetch vendors');
      console.error('Error fetching vendors:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  return {
    vendors,
    isLoading,
    error,
    refetch: fetchVendors,
  };
};

export default useVendors;
