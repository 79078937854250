import { useState, useCallback, useEffect } from 'react';
import auth_axios from 'networking/AuthAxios';
import { format } from 'date-fns';

/**
 * @typedef {Object} ServiceAvailabilityResponse
 * @property {boolean} is_serviceable
 * @property {string} provider
 * @property {string} [message]
 */

/**
 * @typedef {Object} TimeSlot
 * @property {string} slot_time
 * @property {boolean} is_available
 */

/**
 * @typedef {Object} TimeSlotResponse
 * @property {TimeSlot[]} slots
 * @property {string} [message]
 */

// Mock data for slots
export const MOCK_SLOTS = {
  provider: 'orange_health',
  data: {
    status: 'success',
    slots: [
      '2025-03-20T06:00:00',
      '2025-03-20T06:45:00',
      '2025-03-20T07:30:00',
      '2025-03-20T08:15:00',
      '2025-03-20T09:00:00',
    ],
  },
};

const useServiceAvailability = ({ date, geodata }) => {
  const [isChecking, setIsChecking] = useState(false);
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);
  const [data, setData] = useState(null);
  const [slots, setSlots] = useState([]);
  const [timeSlotsError, setTimeSlotsError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const getTimeSlots = useCallback(async (provider, latitude, longitude, date) => {
    if (!provider || !date) return;

    setIsLoadingSlots(true);
    setTimeSlotsError(null);

    try {
      // REAL API IMPLEMENTATION (Commented for now)
      const formattedDate = format(date, 'yyyy-MM-dd');
      const response = await auth_axios.get('/patient_blood_report/get_time_slot_list/', {
        params: {
          provider,
          latitude,
          longitude,
          collection_date: formattedDate,
        },
      });

      setSlots(response.data?.data?.slots || []);
      return response.data;
    } catch (err) {
      setTimeSlotsError(err?.response?.data?.message || 'Failed to fetch time slots');
      setSlots([]);
      return [];
    } finally {
      setIsLoadingSlots(false);
    }
  }, []);

  const checkAvailability = useCallback(
    async (latitude, longitude, date) => {
      if (!date || !latitude || !longitude) return;

      setIsChecking(true);
      setLocationError(null);
      setSlots([]);

      try {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const response = await auth_axios.get('/patient_blood_report/check_location_serviceable/', {
          params: {
            latitude,
            longitude,
            request_date: formattedDate,
          },
        });

        setData(response.data);

        // If service is available, fetch time slots
        if (response.data.is_serviceable && response.data.provider) {
          await getTimeSlots(response.data.provider, latitude, longitude, date);
        }

        return response.data.is_serviceable;
      } catch (err) {
        setLocationError(err?.response?.data?.message || 'Failed to check service availability');
        setData(null);
        return false;
      } finally {
        setIsChecking(false);
      }
    },
    [getTimeSlots]
  );

  useEffect(() => {
    if (date) {
      checkAvailability(geodata?.latitude, geodata?.longitude, date);
    }
  }, [date, checkAvailability]);

  return {
    checkAvailability,
    isChecking,
    isLoadingSlots,
    timeSlotsError,
    data,
    slots,
  };
};

export default useServiceAvailability;
