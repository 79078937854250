import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import auth_axios from 'networking/AuthAxios';

/**
 * Hook to fetch and set initial patient and address data
 * @param {Object} options - Options object
 * @param {Function} options.setSelectedPatient - Function to set selected patient
 * @param {Function} options.setAddresses - Function to set addresses
 * @param {Function} options.setSelectedAddress - Function to set selected address
 * @param {Function} options.setSearchTerm - Function to set search term
 * @returns {Object} Object containing loading and error states
 */
const useInitialPatientData = ({ setSelectedPatient, setAddresses, setSelectedAddress, setSearchTerm }) => {
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get('patientId');

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!patientId) return;

      try {
        // Fetch patient details
        const patientResponse = await auth_axios.get(`patients/${patientId}/`);
        const patientData = patientResponse.data;

        setSelectedPatient(patientData);
        setSearchTerm(patientData.full_name);

        // Fetch patient addresses
        const addressResponse = await auth_axios.get(`patients/${patientId}/patient_address/`);
        const addresses = addressResponse.data;

        setAddresses(addresses);

        // Set first address as selected if available
        if (addresses && addresses.length > 0) {
          setSelectedAddress(addresses[0]);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        // You might want to show a toast message here
      }
    };

    fetchInitialData();
  }, [patientId, setSelectedPatient, setAddresses, setSelectedAddress, setSearchTerm]);
};

export default useInitialPatientData;
