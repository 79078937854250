import { Mail, MapPin, Phone } from 'lucide-react';

const PatientInfoCard = ({ patientData }) => {
  const patientNamePellet = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        border: '1px solid #D1DCFF',
        backgroundColor: '#ECF0FB',
        borderRadius: '20px',
        padding: '4px 8px',
      }}
    >
      <div
        style={{
          width: '30px',
          height: '30px',
          backgroundColor: '#757575',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '20px',
          color: '#fff',
        }}
      >
        {patientData?.full_name?.charAt(0)}
      </div>
      <div style={{ fontSize: '14px', fontWeight: '500', color: '#243B86' }}>{patientData?.full_name}</div>
    </div>
  );

  const patientContactInfo = (
    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', gap: '32px', color: '#666', fontSize: '14px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Phone size={16} color="#243B86" />
              {patientData?.phone}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Mail size={16} color="#243B86" />
              {patientData?.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const patientAddress = (
    <div style={{ width: 250, display: 'flex', gap: '8px' }}>
      <MapPin fill="#243B86" size={20} strokeWidth={0} />
      {patientData.address}
    </div>
  );
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
      {patientNamePellet}
      {patientContactInfo}
      {patientAddress}
    </div>
  );
};

export default PatientInfoCard;
