import React, { useMemo, useState } from 'react';
import BloodTestPatientTable from './BloodTestPatientTable';
import BloodTestTabs from './BloodTestTabs';
import BloodTestHeader from './BloodTestHeader';
import BloodTestFilters from './BloodTestFilters';
import { DEFAULT_ACTIVE_TAB } from './constants';
import Pagination from '../Pagination';
import { AdminPanelHeader } from 'components';
import SearchBox from '../common/SearchBox';
import { Search, TestTube } from 'lucide-react';
import usePatientBloodReports from '../../hooks/usePatientBloodReports';
import { format } from 'date-fns';
import { ToastContainer } from 'react-toastify';

const BloodTests = () => {
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState({ fromDate: null, toDate: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    vendorId: '',
  });

  // Get status filter based on active tab
  const getStatusFilter = (tab) => {
    switch (tab) {
      case 'Pending':
        return 'pending';
      case 'Confirmed':
        return 'confirmed';
      case 'Report Available':
        return 'report_available';
      case 'Cancelled':
        return 'cancelled';
      case 'Completed':
        return 'completed';
      default:
        return null; // All patients
    }
  };

  // Fetch blood reports using our custom hook
  const { data, loading, error, refresh } = usePatientBloodReports({
    currentPage,
    status: getStatusFilter(activeTab),
    searchTerm,
    start_date: filters.startDate,
    end_date: filters.endDate,
    vendor_id: filters.vendorId,
  });

  // Add dummy data if no data is available
  const dummyData = {
    reports: [
      {
        id: 1,
        patient_name: 'John Doe',
        status: 'pending',
        date: '2024-01-15',
        vendor: 'Lab A',
        user: {
          full_name: 'John Doe',
          address: '123 Main St, Anytown, USA',
          phone: '123-456-7890',
        },
        created_at: '2024-01-15',
      },
      {
        id: 2,
        patient_name: 'Jane Smith',
        status: 'confirmed',
        date: '2024-01-14',
        vendor: 'Lab B',
        user: {
          full_name: 'Jane Smith',
          address: '456 Oak Ave, Anytown, USA',
          phone: '123-456-7890',
        },
        created_at: '2024-01-14',
      },
    ],
    total_records: 2,
  };

  const reports = data?.results
    ? [
        // {
        //   id: 'pending-dummy',
        //   patient_name: 'Pending Patient',
        //   status: 'pending',
        //   date: new Date().toISOString().split('T')[0],
        //   vendor: 'Lab X',
        //   user: {
        //     id: 4534,
        //     full_name: 'Pending Patient',
        //     address: '789 Pending St, Anytown, USA',
        //     phone: '555-555-5555',
        //   },
        //   created_at: new Date().toISOString(),
        // },
        ...data?.results,
      ]
    : [];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleDateRangeChange = (fromDate, toDate) => {
    setDateRange({ fromDate, toDate });
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    // Reset to first page when filters change
    setCurrentPage(1);
  };

  const renderSearchBox = useMemo(() => {
    return (
      <div style={{ position: 'relative', flex: 1 }}>
        <input
          style={{ borderRadius: '12px', border: '1px solid #D9D9D9', paddingHorizontal: '6px', width: '100%' }}
          type="text"
          placeholder="Search a patient by name or mobile number"
          className="px-4 py-2 pl-10 border-1 rounded-md"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Search style={{ position: 'absolute', right: '12px', top: '50%', transform: 'translateY(-50%)' }} size={18} />
      </div>
    );
  }, []);

  // Create tab data with counts from API
  const tabData = [
    {
      id: 'All patients',
      label: 'All patients',
      count: data.total_records || 0,
    },
    {
      id: 'Pending',
      label: 'Pending',
      count: 0, // Ideally this would come from API
    },
    {
      id: 'Confirmed',
      label: 'Confirmed',
      count: 0, // Ideally this would come from API
    },
    {
      id: 'Completed',
      label: 'Completed',
      count: 0, // Ideally this would come from API
    },
    {
      id: 'Cancelled',
      label: 'Cancelled',
      count: 0, // Ideally this would come from API
    },
  ];

  // Function to format date for display
  const formatDate = (date) => {
    if (!date) return '';
    return format(new Date(date), 'dd MMM yyyy');
  };

  // Get active filters

  const filterChipStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
    padding: '4px 12px',
    backgroundColor: '#F3F4F6',
    borderRadius: '16px',
    margin: '0 8px 8px 0',
    fontSize: '14px',
  };

  const closeButtonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    marginLeft: '4px',
  };

  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="wrapperContent" style={{ paddingInline: '2rem' }}>
        <div className="my-2" /> {/*space between header and tabs*/}
        <div>
          <AdminPanelHeader
            title="Blood Tests"
            searchBox={renderSearchBox}
            customIcon={TestTube}
            showTree={[{ title: 'Blood Tests', iconClass: 'science' }]}
          />
        </div>
        <div className="line-separator" style={{ borderBottom: '1px solid #D9D9D9', transform: 'translateY(-10px)' }} />
        <BloodTestFilters onFiltersChange={handleFiltersChange} />
        <div
          className="line-separator"
          style={{ borderBottom: '1px solid #D9D9D9', transform: 'translateY(-10px)', marginTop: '10px' }}
        />
        <BloodTestTabs
          tabData={tabData}
          defaultActiveTab={DEFAULT_ACTIVE_TAB}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />
        {/* Filter Chips */}
        <div className="my-2" /> {/*space between tabs and table*/}
        {loading && <div className="text-center py-4">Loading blood test reports...</div>}
        {error && <div className="text-center py-4 text-danger">Error: {error}</div>}
        {/* <BloodTestPatientTable bloodTests={dummyData.reports} /> */}
        {!loading && !error && (
          <>
            <BloodTestPatientTable bloodTests={reports} activeTab={activeTab} />
            <Pagination
              data={undefined}
              length={data.count}
              totalPages={Math.ceil(data?.count / 10)}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BloodTests;
