import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddBloodTestDrawer from './AddBloodTestDrawer';
import AddAddressDrawer from './AddAddressDrawer';
import debounce from 'lodash/debounce';
import auth_axios from 'networking/AuthAxios';
import './AddBloodTest.css';
import { formatAddress } from './utils';
import useInitialPatientData from '../../hooks/useInitialPatientData';

const AddBloodTest = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAddressDrawerOpen, setIsAddressDrawerOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [formData, setFormData] = useState({
    homeAddress: null,
    otherAddress: null,
  });

  // Use the new hook to fetch initial data if patientId is present in the URL
  useInitialPatientData({
    setSelectedPatient,
    setAddresses,
    setSelectedAddress,
    setSearchTerm,
  });

  const fetchPatientAddresses = useCallback(async (patientId) => {
    try {
      const response = await auth_axios.get(`patients/${patientId}/patient_address`);
      setAddresses(response.data);
      // Set the first address as selected if available
      if (response.data && response.data.length > 0) {
        setSelectedAddress(response.data[0]);
        setFormData((prev) => ({
          ...prev,
          homeAddress: response.data[0],
        }));
      }
    } catch (error) {
      console.error('Error fetching patient addresses:', error);
    }
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSearchResults([]);
        return;
      }

      setLoading(true);
      try {
        const response = await auth_axios.get(`patients/search/?search_str=${encodeURIComponent(query)}&page=1`);
        setSearchResults(response.data || []);
      } catch (error) {
        console.error('Error searching patients:', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, debouncedSearch]);

  const handlePatientSelect = (patient) => {
    setSelectedPatient(patient);
    setSearchTerm(patient.full_name);
    setShowResults(false);
    // Fetch patient addresses when a patient is selected
    if (patient?.id) {
      fetchPatientAddresses(patient.id);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedPatient) {
      return;
    }
    setIsDrawerOpen(true);
  };

  const handleAddressChange = (field) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };
  const navigateToBloodTest = () => {
    const t = setTimeout(() => {
      navigate(`/blood-tests`);
      clearTimeout(t);
    }, 500);
  };
  const handleAddAddress = async (addressData) => {
    if (!selectedPatient?.id) return;

    try {
      const payload = {
        id: null,
        address_type: addressData.label.toLowerCase(),
        address_line1: addressData.addressLine1,
        address_line2: addressData.addressLine2,
        city: addressData.city,
        pincode: addressData.pincode,
        state: addressData.state || '',
        patientId: selectedPatient.id,
      };

      await auth_axios.post(`patients/${selectedPatient.id}/patient_address/`, payload);

      // Refresh the addresses list
      await fetchPatientAddresses(selectedPatient.id);
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    setFormData((prev) => ({
      ...prev,
      homeAddress: address,
    }));
  };

  const addressCardStyles = {
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    cursor: 'pointer',
  };

  const selectedAddressStyles = {
    ...addressCardStyles,
    borderColor: '#38447E',
    backgroundColor: '#F8F9FF',
  };

  const radioStyles = {
    width: '20px',
    height: '20px',
    marginTop: '2px',
  };

  const addressTextStyles = {
    flex: 1,
    fontSize: '14px',
    lineHeight: '1.5',
  };

  return (
    <div className="add-blood-test-container">
      <div className="wrapper-content add-blood-test-content">
        <div className="add-blood-test-form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="patientName">Patient Name*</label>
              <div className="search-container">
                <input
                  type="text"
                  id="patientName"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setShowResults(true);
                  }}
                  onFocus={() => setShowResults(true)}
                  placeholder="Type to search patient"
                  required
                  className="search-input"
                />
                {loading && <div className="loading-indicator">Loading...</div>}
                {showResults && searchResults.length > 0 && (
                  <div className="search-results">
                    {searchResults.map((patient) => (
                      <div key={patient.id} className="search-result-item" onClick={() => handlePatientSelect(patient)}>
                        {patient.full_name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              {selectedPatient && <label>Select Address:</label>}
              <div style={{ marginTop: '12px' }}>
                {addresses.map((address) => (
                  <div
                    key={address.id}
                    style={selectedAddress?.id === address.id ? selectedAddressStyles : addressCardStyles}
                    onClick={() => handleAddressSelect(address)}
                  >
                    <input
                      type="radio"
                      name="address"
                      checked={selectedAddress?.id === address.id}
                      onChange={() => handleAddressSelect(address)}
                      style={radioStyles}
                    />
                    <div style={addressTextStyles}>
                      <div style={{ fontWeight: 500, marginBottom: '4px', textTransform: 'capitalize' }}>
                        {address.address_type}
                      </div>
                      <div>{formatAddress(address)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {selectedPatient && (
              <button
                type="button"
                onClick={() => setIsAddressDrawerOpen(true)}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#38447E',
                  cursor: 'pointer',
                  padding: '8px 0',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  marginTop: '8px',
                  marginBottom: '24px',
                }}
              >
                {addresses.length > 1 ? 'Edit Address' : 'Add New Address'}
              </button>
            )}
            <div className="form-actions">
              <button type="button" onClick={() => navigate(-1)} className="button button-secondary">
                Cancel
              </button>
              <button type="submit" disabled={!selectedPatient || !selectedAddress} className="button button-primary">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>

      <AddBloodTestDrawer
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          // setSelectedPatient(null);
          // setFormData({
          //   homeAddress: null,
          //   otherAddress: null,
          // });
        }}
        callback={navigateToBloodTest}
        patientData={selectedPatient}
        address={selectedAddress}
      />

      <AddAddressDrawer
        open={isAddressDrawerOpen}
        onClose={() => {
          setIsAddressDrawerOpen(false);
          // setSelectedPatient(null);
        }}
        onAddAddress={handleAddAddress}
      />
    </div>
  );
};

export default AddBloodTest;
