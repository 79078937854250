import { useState, useEffect, useCallback } from 'react';
import auth_axios from 'networking/AuthAxios';

/**
 * @typedef {Object} BloodTestReportDetails
 * @property {number} id
 * @property {string} name
 * @property {string} status
 * @property {string} scheduled_date
 * @property {string} scheduled_time
 * @property {Object} vendor
 * @property {Object} user
 * @property {Object} address
 */

/**
 * Hook to fetch blood report details for a specific patient
 * @param {string|number} patientId - The ID of the patient
 * @returns {Object} Blood report details, loading state, error state, and refresh function
 */
const usePatientBloodReportDetails = (patientId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchReportDetails = useCallback(async () => {
    if (!patientId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await auth_axios.get(`/patient_blood_report/${patientId}/`);
      setData(response.data);
      return response.data;
    } catch (err) {
      console.error('Error fetching blood report details:', err);
      setError(err.message || 'Failed to fetch blood report details');

      // For development, you can return mock data
      // const mockData = {
      //   id: patientId,
      //   name: 'John Doe',
      //   status: 'Confirmed',
      //   scheduled_date: '2024-03-21',
      //   scheduled_time: '14:30:00',
      //   vendor: {
      //     id: 1,
      //     vendor_name: 'Thyrocare',
      //   },
      //   user: {
      //     id: patientId,
      //     full_name: 'John Doe',
      //     phone: '9876543210',
      //     email: 'john@example.com',
      //   },
      //   address: {
      //     address_line1: '123 Main St',
      //     address_line2: 'Apt 4B',
      //     city: 'Mumbai',
      //     state: 'Maharashtra',
      //     pincode: '400001',
      //   },
      // };
      // setData(mockData);
    } finally {
      setLoading(false);
    }
  }, [patientId]);

  useEffect(() => {
    fetchReportDetails();
  }, [fetchReportDetails]);

  return {
    data,
    loading,
    error,
    refresh: fetchReportDetails,
  };
};

export default usePatientBloodReportDetails;
