import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime, getStatusColor, formatAddress, formatDateSafe, formatTimeSafe } from './utils';
import { DUMMY_BLOOD_TESTS } from './constants';
import { DownloadIcon, MoreVerticalIcon, EyeIcon } from 'lucide-react';

// Status badge component for better visualization
const StatusBadge = ({ status }) => {
  return (
    <div
      style={{
        backgroundColor: getStatusColor(),
        color: 'white',
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'inline-block',
        fontSize: '12px',
        fontWeight: 'bold',
      }}
    >
      {status}
    </div>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.oneOf(['Pending', 'Confirmed', 'Report Available', 'Cancelled']).isRequired,
};

// Main component
const BloodTestPatientTable = ({ bloodTests = [], activeTab = 'All patients' }) => {
  const navigate = useNavigate();

  const handleViewDetails = (recordId) => {
    navigate(`/blood-tests/${recordId}/details`);
  };

  // Define all possible columns
  const allColumns = {
    name: {
      title: 'Name',
      render: (record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 500 }}>{record?.user?.full_name || '-'}</div>
        </div>
      ),
    },
    date: {
      title: 'Date',
      render: (record) => <div>{record?.appointment_date ? formatDateSafe(record.appointment_date) : '-'}</div>,
    },
    time: {
      title: 'Time',
      render: (record) => <div>{record?.slot_time ? formatTimeSafe(record.slot_time) : '-'}</div>,
    },
    address: {
      title: 'Address',
      render: (record) => <div>{record?.address ? formatAddress(record?.address) : '-'}</div>,
    },
    phone: {
      title: 'Ph. Number',
      render: (record) => <div>{record?.user?.phone || '-'}</div>,
    },
    vendor: {
      title: 'Vendor',
      render: (record) => <div>{record.vendor?.vendor_name || '-'}</div>,
    },
    status: {
      title: 'Status',
      render: (record) => {
        const status = record.status || 'Pending';
        const colors = getStatusColor(status);
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
            <Chip
              label={status}
              size="small"
              sx={{
                backgroundColor: colors.bg,
                color: colors.text,
                borderRadius: '16px',
                padding: '2px 8px',
                '& .MuiChip-label': {
                  padding: 0,
                },
              }}
            />
          </div>
        );
      },
    },
    view: {
      title: '',
      render: (record) => (
        <div style={{ marginLeft: '0.5rem' }}>
          <MoreVerticalIcon
            style={{ color: '#666666', cursor: 'pointer' }}
            onClick={() => handleViewDetails(record.id)}
          />
        </div>
      ),
    },
    action: {
      title: 'Action',
      render: (record) => (
        <div style={{ display: 'flex', gap: '4px' }}>
          <button
            style={{ backgroundColor: 'transparent', color: '#243B86' }}
            onClick={() => navigate(`/blood-tests/add-blood-tests?patientId=${record?.user?.id}`)}
          >
            Book Now
          </button>
        </div>
      ),
    },
    reports: {
      title: 'Reports',
      render: (record) => {
        // const reportUrl =
        //   'https://files.orangehealth.in/api/media-url/?fileId=71349502&token=666f7573-b3e5-4c10-90c0-88aecbb439d5';
        const reportUrl = record?.report;
        if (!reportUrl) {
          return <div>No report available</div>;
        }
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <div
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                padding: '4px',
              }}
              onClick={() => {
                if (reportUrl) {
                  window.open(reportUrl, '_blank');
                }
              }}
            >
              <EyeIcon size={16} color="#243B86" />
            </div>
            <div
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                padding: '4px',
              }}
              onClick={() => {
                if (reportUrl) {
                  const link = document.createElement('a');
                  link.href = reportUrl;
                  link.download = `blood_test_report_${record.id}.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }}
            >
              <DownloadIcon size={16} color="#243B86" />
            </div>
          </div>
        );
      },
    },
  };

  // Define column configurations for each tab
  const tabColumns = {
    'All patients': ['name', 'date', 'time', 'address', 'phone', 'vendor', 'status', 'view'],
    Pending: ['name', 'address', 'phone', 'action'],
    Completed: ['name', 'date', 'time', 'address', 'phone', 'vendor', 'reports', 'view'],
    Confirmed: ['name', 'date', 'time', 'address', 'phone', 'vendor', 'view'],
    'Report Available': ['name', 'date', 'time', 'vendor', 'view'],
    Cancelled: ['name', 'date', 'time', 'vendor', 'view'],
  };

  // Get the columns for the current tab
  const activeColumns = tabColumns[activeTab] || tabColumns['All patients'];
  const columns = activeColumns.map((key) => allColumns[key]);

  return (
    <div style={{ marginBottom: '2.5rem' }}>
      <TableContainer component={Paper} className="overflow-x-auto" sx={{ boxShadow: 'none', borderRadius: 0 }}>
        <Table className="min-w-full">
          <TableHead style={{ background: '#F3F3F3' }}>
            <TableRow className="bg-gray-100">
              {columns.map((column, index) => (
                <TableCell key={index} style={{ fontWeight: '600' }}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bloodTests?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
            {bloodTests?.map((test) => (
              <TableRow
                key={test.id}
                className="hover:bg-gray-100 cursor-pointer transition-colors duration-150"
                style={{ transition: 'background-color 0.15s ease-in-out' }}
              >
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{
                      maxWidth: '200px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {column.render ? column.render(test) : test[column.field] || '-'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// Update PropTypes to include activeTab
BloodTestPatientTable.propTypes = {
  bloodTests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
      date: PropTypes.string,
      time: PropTypes.string,
      address: PropTypes.string,
      phone_number: PropTypes.string,
      vendor_name: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  activeTab: PropTypes.oneOf(['All patients', 'Pending', 'Confirmed', 'Report Available', 'Cancelled']),
};

export default BloodTestPatientTable;
