export const STATUS_COLORS = {
  Pending: { bg: '#FFF8E1', text: '#FFA000' }, // Yellow
  Confirmed: { bg: '#E8F5E9', text: '#2E7D32' }, // Green
  'Report Available': { bg: '#E3F2FD', text: '#1565C0' }, // Blue
  Cancelled: { bg: '#FFEBEE', text: '#C62828' }, // Red
  Rescheduled: { bg: '#E3F2FD', text: '#1565C0' }, // Blue
};
export const DEFAULT_ACTIVE_TAB = 'All patients';
export const DUMMY_BLOOD_TESTS = [
  {
    id: 1,
    patientName: 'John Doe',
    date: new Date('2023-10-15'),
    time: new Date('2023-10-15T10:00:00'),
    address: '123 Main St, Anytown, CA',
    phoneNumber: '(555) 123-4567',
    vendor: 'LabCorp',
    status: 'Pending',
  },
  {
    id: 2,
    patientName: 'Jane Smith',
    date: new Date('2023-10-16'),
    time: new Date('2023-10-16T14:30:00'),
    address: '456 Oak Ave, Somewhere, NY',
    phoneNumber: '(555) 987-6543',
    vendor: 'Quest Diagnostics',
    status: 'Confirmed',
  },
  {
    id: 3,
    patientName: 'Robert Johnson',
    date: new Date('2023-10-14'),
    time: new Date('2023-10-14T09:15:00'),
    address: '789 Pine Rd, Nowhere, TX',
    phoneNumber: '(555) 456-7890',
    vendor: 'LabCorp',
    status: 'Report Available',
  },
  {
    id: 4,
    patientName: 'Emily Davis',
    date: new Date('2023-10-13'),
    time: new Date('2023-10-13T11:45:00'),
    address: '321 Elm Blvd, Anyplace, FL',
    phoneNumber: '(555) 789-0123',
    vendor: 'Quest Diagnostics',
    status: 'Cancelled',
  },
];

export const tabData = [
  {
    id: 'All patients',
    label: 'All patients',
    count: 10,
  },
  {
    id: 'Pending',
    label: 'Pending',
    count: 10,
  },
  {
    id: 'Confirmed',
    label: 'Confirmed',
    count: 10,
  },
  {
    id: 'Report Available',
    label: 'Report Available',
    count: 10,
  },
  {
    id: 'Cancelled',
    label: 'Cancelled',
    count: 10,
  },
];
