import { format } from 'date-fns';
import { STATUS_COLORS } from './constants';
import React from 'react';
import PropTypes from 'prop-types';

export const formatDate = (date) => {
  if (!date) return '-';
  try {
    return format(new Date(date), 'dd.MM.yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return '-';
  }
};

export const formatTime = (time) => {
  if (!time) return '-';
  try {
    // Check if time is a string before using includes()
    if (typeof time === 'string' && (time.includes('AM') || time.includes('PM'))) {
      return time; // Return as is if it's already in AM/PM format
    }
    return format(new Date(`${time}`), 'h:mm a');
  } catch (error) {
    console.error('Error formatting time:', error);
    return '-';
  }
};
export const formatDateSafe = (date) => {
  if (!date) return '-';
  try {
    return formatDate(date);
  } catch (error) {
    console.error('Error formatting date:', error);
    return '-';
  }
};

export const formatTimeSafe = (time) => {
  if (!time) return '-';
  try {
    return formatTime(time);
  } catch (error) {
    console.error('Error formatting time:', error);
    return '-';
  }
};

export const getStatusColor = (status) => {
  const normalizedStatus = status ? status.toLowerCase() : '';

  const statusMap = {
    pending: STATUS_COLORS.Pending,
    confirmed: STATUS_COLORS.Confirmed,
    completed: STATUS_COLORS['Report Available'],
    'report available': STATUS_COLORS['Report Available'],
    cancelled: STATUS_COLORS.Cancelled,
    main_booking: STATUS_COLORS.Pending,
    rescheduled: STATUS_COLORS.Rescheduled,
  };

  return statusMap[normalizedStatus] || { bg: '#F5F5F5', text: '#616161' }; // Default grey
};

export const TabLabel = ({ label, count }) => (
  <span>
    {label} {count ? `(${count})` : ''}
  </span>
);

TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

export const formatAddress = (address) => {
  if (!address) return '-';
  return `${address.address_line1}, ${address.city}, ${address.state}, ${address.pincode}`;
};

/**
 * Get latitude and longitude from address using Google Geocoding API
 * @param {Object} address - Address object containing address details
 * @returns {Promise<{latitude: string, longitude: string}>} - Object containing latitude and longitude
 */
export const getCoordinatesFromAddress = async (address) => {
  try {
    const addressString = formatAddress(address);
    const encodedAddress = encodeURIComponent(addressString);
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();

    if (data.status === 'OK' && data.results && data.results[0]) {
      const { lat, lng } = data.results[0].geometry.location;
      return {
        latitude: lat.toString(),
        longitude: lng.toString(),
      };
    }
    throw new Error('Unable to get coordinates from address');
  } catch (error) {
    console.error('Error getting coordinates:', error);
    throw error;
  }
};
