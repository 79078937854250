import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';
import { TabLabel } from './utils';

const BloodTestTabs = ({ defaultActiveTab, tabData, activeTab, onTabChange }) => {
  const handleTabChange = (event, newValue) => {
    onTabChange?.(newValue);
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        // borderBottom: 1,
        marginBottom: '-1px', // Reduce space between indicator and text
        borderColor: 'divider',
        '& .MuiTabs-indicator': {
          backgroundColor: '#243B86',
          marginBottom: '10px', // Reduce space between indicator and text
        },
      }}
    >
      {tabData.map(({ id, label, count }) => (
        <Tab
          key={id}
          value={id}
          label={<TabLabel label={label} count={count === 0 ? '' : count} />}
          sx={{
            width: '200px',
            textTransform: 'none',
            color: 'text.secondary',
            '&.Mui-selected': {
              color: '#243B86',
              fontWeight: 'bold',
            },
          }}
        />
      ))}
    </Tabs>
  );
};

BloodTestTabs.propTypes = {
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func,
};

export default BloodTestTabs;
