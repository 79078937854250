import React from 'react';
import { useNavigate } from 'react-router-dom';

export const InvoicesTable = ({ orderList, isLoading }) => {
  const navigate = useNavigate();

  if (!orderList?.length && !isLoading) {
    return (
      <tr>
        <td colSpan={8} className="text-center">No data found</td>
      </tr>
    );
  }

  return orderList.map((item) => (
    <tr key={item?.order} style={{ height: '50px' }}>
      <td>{item?.order_details?.order_id}</td>
      <td>
        <b>{item?.items[0]?.vendor?.vendor_name}</b>
      </td>
      <td>{item?.invoice_date}</td>
      <td>₹ {item?.vendor_amount}</td>
      <td>
        <div
          onClick={() => {
            navigate(`/orders/${item?.order}/${item?.user}/view-invoice/${item?.items[0]?.vendor?.id}`);
          }}
          style={{ color: '#243B86', cursor: 'pointer' }}
        >
          View Details
        </div>
      </td>
    </tr>
  ));
}; 