import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import useServiceAvailability from '../../hooks/useServiceAvailability';
import useScheduleBloodTest from '../../hooks/useScheduleBloodTest';
import useAddressCoordinates from '../../hooks/useAddressCoordinates';
import useVendors from '../../hooks/useVendors';
import { toast, ToastContainer } from 'react-toastify';
import { formatTimeSafe } from './utils';
import { format } from 'date-fns';

/**
 * @typedef {Object} PatientData
 * @property {string|number} id
 * @property {string} full_name
 * @property {string} pincode
 */

/**
 * @typedef {Object} Props
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {PatientData} patientData
 */

/** @type {React.FC<Props>} */

const AddBloodTestDrawer = ({ open, onClose, patientData, address, callback }) => {
  const [date, setDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedVendorId, setSelectedVendorId] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [isManualBooking, setIsManualBooking] = useState(true);
  const {
    coordinates: geodata,
    isLoading: isLoadingCoordinates,
    setCoordinates: setGeodata,
  } = useAddressCoordinates(address);
  const { vendors, isLoading: isLoadingVendors, error: vendorsError } = useVendors();

  const { data, isChecking, isLoadingSlots, timeSlotsError, locationError, slots } = useServiceAvailability({
    date,
    geodata,
  });
  const { scheduleTest, isScheduling, error: scheduleError } = useScheduleBloodTest();
  const isServiceable = data?.is_serviceable;
  const provider = data?.provider;

  const containerStyle = {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: '400px',
    backgroundColor: 'white',
    boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    padding: '24px',
    display: open ? 'block' : 'none',
    overflowY: 'auto',
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
  };

  const closeButtonStyle = {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: '8px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '500',
  };

  const dateContainerStyle = {
    display: 'flex',
    marginBottom: '16px',
    flexWrap: 'wrap',
    gap: '10px',
  };

  const dateButtonStyle = (isSelected) => ({
    padding: '10px',
    borderRadius: '12px',
    backgroundColor: isSelected ? '#FF7043' : '#F5F5F5',
    color: isSelected ? 'white' : 'black',
    cursor: 'pointer',
    border: 'none',
    width: '50px',
    textAlign: 'center',
  });

  const slotsContainerStyle = {
    display: 'flex',
    marginTop: '16px',
    flexWrap: 'wrap',
    gap: '10px',
  };

  const slotButtonStyle = (isSelected) => ({
    padding: '10px',
    borderRadius: '12px',
    backgroundColor: isSelected ? '#FF7043' : '#F5F5F5',
    color: isSelected ? 'white' : 'black',
    cursor: 'pointer',
    border: 'none',
    width: '60px',
    textAlign: 'center',
  });

  const buttonStyle = {
    width: '100%',
    padding: '14px',
    backgroundColor: '#243B86',
    color: 'white',
    border: 'none',
    borderRadius: '12px',
    marginTop: '24px',
    cursor: 'pointer',
    opacity: !date || !selectedSlot || isChecking || isLoadingSlots || !isServiceable ? 0.5 : 1,
  };

  const timeSlots = Array.from({ length: 24 }, (_, i) => {
    if (!date) return null;

    const hour = i.toString().padStart(2, '0');
    const formattedDate = format(date, 'yyyy-MM-dd');
    return {
      value: `${formattedDate}T${hour}:00:00`, // Full ISO string for API
      display: `${hour}:00`, // Display time only for user
    };
  }).filter(Boolean);
  const handleSchedule = async () => {
    try {
      const scheduleResponse = await scheduleTest({
        provider,
        userId: patientData.id,
        addressId: address.id,
        customerLatitude: geodata?.latitude,
        customerLongitude: geodata?.longitude,
        landmark: address.address_line1,
        collection_date: format(new Date(date), 'yyyy-MM-dd'),
        collection_slot: selectedSlot?.slot_datetime?.split('T')[1],
        slotId: selectedSlot?.id,
        customerAge: patientData.age,
        testId: '2', // TODO: get test id
        gender: patientData.gender || '',
        pincode: address.pincode || '',
      });

      toast.success(
        `Blood test ${scheduleResponse?.booking_id} at ${selectedSlot?.slot_datetime} scheduled successfully`
      );

      const t = setTimeout(() => {
        callback?.();
        clearTimeout(t);
      }, 1000);
    } catch (err) {
      console.error('Failed to schedule test:', err);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleManualSchedule = async () => {
    try {
      const scheduleResponse = await scheduleTest({
        provider: 'dashboard',
        userId: patientData.id,
        addressId: address.id,
        customerLatitude: geodata?.latitude,
        customerLongitude: geodata?.longitude,
        landmark: address.address_line1,
        collection_date: format(new Date(date), 'yyyy-MM-dd'),
        collection_slot: selectedTime?.split('T')[1],
        slotId: null, // No slot ID for manual bookings
        customerAge: patientData.age,
        testId: '2',
        gender: patientData.gender || '',
        pincode: address.pincode || '',
        is_manual_booking: true,
        vendor_id: selectedVendorId,
      });

      toast.success(`Blood test scheduled with ${selectedVendor} for ${selectedTime}`);

      const t = setTimeout(() => {
        callback?.();
        clearTimeout(t);
      }, 1000);
    } catch (err) {
      console.error('Failed to schedule test:', err);
      toast.error(err?.response?.data?.message);
    }
  };
  const noVendorAvailable = !isServiceable && !isChecking;
  return (
    <div style={containerStyle}>
      <ToastContainer />
      <div style={headerStyle}>
        <h2 style={{ margin: 0, fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>Add Blood Test</h2>
        <button style={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div style={{ marginBottom: '24px' }}>
        <label style={labelStyle}>Location Coordinates</label>
        <div style={{ display: 'flex', gap: '16px' }}>
          <div style={{ flex: 1 }}>
            <label style={{ fontSize: '14px', color: '#374151', marginBottom: '4px', display: 'block' }}>
              Latitude
            </label>
            <input
              type="text"
              placeholder="Latitude"
              value={geodata?.latitude}
              onChange={(e) => setGeodata((prev) => ({ ...prev, latitude: e.target.value }))}
              style={{
                width: '100%',
                padding: '8px 12px',
                border: '1px solid #E5E7EB',
                borderRadius: '8px',
                fontSize: '14px',
              }}
              disabled={isLoadingCoordinates}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ fontSize: '14px', color: '#374151', marginBottom: '4px', display: 'block' }}>
              Longitude
            </label>
            <input
              type="text"
              placeholder="Longitude"
              value={geodata?.longitude}
              onChange={(e) => setGeodata((prev) => ({ ...prev, longitude: e.target.value }))}
              style={{
                width: '100%',
                padding: '8px 12px',
                border: '1px solid #E5E7EB',
                borderRadius: '8px',
                fontSize: '14px',
              }}
              disabled={isLoadingCoordinates}
            />
          </div>
        </div>
        {isLoadingCoordinates && (
          <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>Getting coordinates from address...</div>
        )}
      </div>
      <div>
        <label style={labelStyle}>Select a date</label>
        <div style={dateContainerStyle}>
          {[...Array(5)].map((_, index) => {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + index);
            return (
              <button
                key={index}
                onClick={() => setDate(currentDate)}
                style={dateButtonStyle(date?.toDateString() === currentDate.toDateString())}
              >
                {currentDate.getDate()}
                <br />
                {currentDate.toLocaleDateString('en-US', { weekday: 'short' })}
              </button>
            );
          })}
        </div>
        {isChecking && <p style={{ color: '#1976d2' }}>Checking availability...</p>}
        {!isChecking && isServiceable && provider && !noVendorAvailable && (
          <div className="my-4">
            <label style={labelStyle}>Available Vendor</label>
            <div style={{ padding: '12px', backgroundColor: '#f0f0f0', borderRadius: '8px', color: '#222' }}>
              {provider}
            </div>
          </div>
        )}
        {locationError && <p style={{ color: 'red' }}>{locationError}</p>}
        {isLoadingSlots && <p style={{ color: '#1976d2' }}>Loading available slots...</p>}
        {!isChecking && !isLoadingSlots && isServiceable && slots.length > 0 && (
          <>
            <label style={labelStyle}>Available Time</label>
            <div style={slotsContainerStyle}>
              {slots.map((slot) => {
                const slotTime = formatTimeSafe(slot?.slot_datetime);

                return (
                  <button
                    key={slotTime}
                    onClick={() => setSelectedSlot(slot)}
                    style={slotButtonStyle(selectedSlot?.slot_datetime === slot?.slot_datetime)}
                    // disabled={!slot.is_available}
                  >
                    {slotTime}
                  </button>
                );
              })}
            </div>
          </>
        )}

        {noVendorAvailable && date && (
          <div style={{ marginTop: '16px' }}>
            {/* <div
              style={{
                backgroundColor: '#FFF3E0',
                padding: '12px',
                borderRadius: '8px',
                marginBottom: '16px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <span style={{ color: '#E65100' }}>⚠️</span>
              <span style={{ color: '#E65100' }}>
                Service not available in this area, please book manually with a custom vendor
              </span>
            </div> */}

            {/* <button
              onClick={() => setIsManualBooking(!isManualBooking)}
              style={{
                width: '100%',
                padding: '12px',
                backgroundColor: '#FFF3E0',
                color: '#E65100',
                border: '1px solid #FFB74D',
                borderRadius: '8px',
                marginBottom: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                fontWeight: '500',
              }}
            >
              {isManualBooking ? 'Hide Manual Booking Options' : 'Book Manually with Custom Vendor'}
            </button> */}

            <div style={{ marginTop: '16px' }}>
              <label style={labelStyle}>Select Vendor</label>
              {isLoadingVendors ? (
                <div
                  style={{
                    padding: '12px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    textAlign: 'center',
                    color: '#666',
                  }}
                >
                  Loading vendors...
                </div>
              ) : vendorsError ? (
                <div
                  style={{
                    padding: '12px',
                    backgroundColor: '#ffebee',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    color: '#d32f2f',
                  }}
                >
                  {vendorsError}
                </div>
              ) : (
                <select
                  value={selectedVendorId}
                  onChange={(e) => setSelectedVendorId(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '12px',
                    border: '1px solid #E5E7EB',
                    borderRadius: '8px',
                    fontSize: '14px',
                    backgroundColor: 'white',
                    marginBottom: '16px',
                  }}
                >
                  <option value="">Select a vendor</option>
                  {vendors.map((vendor) => (
                    <option key={vendor.id} value={vendor.id}>
                      {vendor.vendor_name}
                    </option>
                  ))}
                </select>
              )}

              <label style={labelStyle}>Select Time</label>
              <select
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                style={{
                  width: '100%',
                  padding: '12px',
                  border: '1px solid #E5E7EB',
                  borderRadius: '8px',
                  fontSize: '14px',
                  backgroundColor: 'white',
                  marginBottom: '16px',
                }}
              >
                <option value="">Select a time</option>
                {timeSlots.map((time) => (
                  <option key={time.value} value={time.value}>
                    {time.display}
                  </option>
                ))}
              </select>

              <button
                style={{
                  ...buttonStyle,
                  opacity: !selectedVendorId || !selectedTime ? 0.5 : 1,
                }}
                onClick={handleManualSchedule}
                disabled={!selectedVendorId || !selectedTime}
              >
                Schedule
              </button>
            </div>
          </div>
        )}

        {/* {timeSlotsError && <p style={{ color: 'red' }}>{timeSlotsError}</p>} */}
        {scheduleError && <p style={{ color: 'red' }}>{scheduleError?.response?.data?.message}</p>}
        {isServiceable && (
          <button
            style={buttonStyle}
            onClick={handleSchedule}
            disabled={!date || !selectedSlot || isChecking || isLoadingSlots || isScheduling}
          >
            {isScheduling ? 'Scheduling...' : 'Schedule'}
          </button>
        )}
      </div>
    </div>
  );
};

AddBloodTestDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    full_name: PropTypes.string,
    pincode: PropTypes.string,
    address_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gender: PropTypes.oneOf(['male', 'female', 'other']),
  }),
};

export default AddBloodTestDrawer;
