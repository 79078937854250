import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Plus, Filter, X } from 'lucide-react';
import BloodTestFilterDrawer from './BloodTestFilterDrawer';
import SearchBox from '../common/SearchBox';
import { format } from 'date-fns';
import useVendors from 'whealth-core-web/hooks/useVendors';
const BloodTestFilters = ({ onFiltersChange }) => {
  const navigate = useNavigate();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const { vendors, isLoading: isLoadingVendors, error: vendorsError } = useVendors();
  const [activeFilters, setActiveFilters] = useState({
    endDate: null,
    startDate: null,
    vendorId: '',
  });
  //add orange health to vendors list
  const updatedVendors = [...vendors];
  updatedVendors.push({
    id: 18,
    vendor_name: 'Orange Health',
    vendor_logo: null,
    contact_person: 'Orange Health Support',
    phone: '0000000000',
    country_code: '+91',
    display_name: null,
  });

  const selectedVendor = updatedVendors?.find((vendor) => String(vendor.id) === String(activeFilters?.vendorId));
  const handleAddBloodTests = () => {
    navigate('/blood-tests/add-blood-tests');
  };

  const handleApplyFilters = (filters) => {
    setActiveFilters(filters);
    onFiltersChange(filters);
    setIsFilterDrawerOpen(false);
  };

  const handleRemoveFilter = (filterKey) => {
    const updatedFilters = {
      ...activeFilters,
      [filterKey]: null,
    };
    setActiveFilters(updatedFilters);
    onFiltersChange(updatedFilters);
  };

  const filterChipStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 12px',
    backgroundColor: '#4B69BD',
    borderRadius: '20px',
    // margin: '0 8px 8px 0',
    fontSize: '14px',
    color: '#FFFFFF',
    border: 'none',
    fontWeight: '500',
  };

  const closeButtonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    color: '#FFFFFF',
  };

  const getFilterChips = () => {
    const chips = [];
    if (activeFilters.startDate) {
      chips.push({
        key: 'startDate',
        label: `Start Date: ${format(activeFilters.startDate, 'dd MMM yyyy')}`,
      });
    }
    if (activeFilters.endDate) {
      chips.push({
        key: 'endDate',
        label: `End Date: ${format(activeFilters.endDate, 'dd MMM yyyy')}`,
      });
    }

    if (activeFilters.vendorId) {
      chips.push({
        key: 'vendorId',
        label: `Vendor: ${selectedVendor?.vendor_name}`,
      });
    }

    return chips;
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          my: 2,
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {/* Filter Chips */}
          {getFilterChips().length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
              {getFilterChips().map((filter) => (
                <div key={filter.key} style={filterChipStyle}>
                  {filter.label}
                  <button
                    onClick={() => handleRemoveFilter(filter.key)}
                    style={closeButtonStyle}
                    aria-label={`Remove ${filter.label}`}
                  >
                    <div
                      style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '100%',
                        height: '16px',
                        width: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <X size={14} color="#4B69BD" />
                    </div>
                  </button>
                </div>
              ))}
              {getFilterChips().length > 0 && (
                <Button
                  variant="text"
                  onClick={() => {
                    setActiveFilters({
                      startDate: null,
                      endDate: null,
                      vendorId: '',
                    });
                    onFiltersChange({
                      startDate: null,
                      endDate: null,
                      vendorId: '',
                    });
                  }}
                  sx={{
                    border: '1px solid #4B69BD',
                    borderRadius: '20px',
                    color: '#243B86',
                    fontSize: '14px',
                    textTransform: 'none',
                    padding: '1px 8px',
                    minWidth: 'auto',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  Clear all
                </Button>
              )}
            </Box>
          )}
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<Filter size={12} fill="#243B86" />}
              onClick={() => setIsFilterDrawerOpen(true)}
              sx={{
                color: '#243B86',
                borderColor: '#243B86',
                borderRadius: '10px',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
            >
              Filter
            </Button>

            {/* <Button
              variant="outlined"
              sx={{
                color: '#243B86',
                borderColor: '#243B86',
                borderRadius: '10px',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
            >
              Export
            </Button> */}

            <Button
              variant="contained"
              startIcon={<Plus size={12} />}
              onClick={handleAddBloodTests}
              sx={{
                color: '#fff',
                backgroundColor: '#243B86',
                borderRadius: '10px',
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
            >
              Add blood tests
            </Button>
          </Box>
        </Box>
      </Box>

      <BloodTestFilterDrawer
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        onApply={handleApplyFilters}
        initialFilters={activeFilters}
        vendors={updatedVendors}
        isLoadingVendors={isLoadingVendors}
        vendorsError={vendorsError}
      />
    </>
  );
};

export default BloodTestFilters;
