import React from 'react';
import { X, Calendar, Truck, Briefcase, FileText } from 'lucide-react';
import { format, parseISO } from 'date-fns';

const TrackBloodTestDrawer = ({ open, onClose, trackingData }) => {
  if (!open) return null;
  if (!trackingData) return <div>No tracking data</div>;

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const drawerStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white',
    boxShadow: '-2px 0 4px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    zIndex: 1001,
    overflowY: 'auto',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '16px',
    right: '16px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f3f4f6',
  };

  const timelineContainerStyle = {
    position: 'relative',
    paddingTop: '40px',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    position: 'relative',
    zIndex: 2,
    padding: '0 24px',
  };

  const getLineStyle = () => {
    const totalSteps = 4;
    let completedSteps = 0;

    completedSteps++; //created default
    if (trackingData?.['task-accepted']) completedSteps++;
    if (trackingData?.['task-in-progress']) completedSteps++;
    if (trackingData?.['order-completed']) completedSteps++;

    const percentage = (completedSteps / totalSteps) * 100;

    return {
      position: 'absolute',
      top: '65px',
      left: '74px',
      right: '74px',
      height: '2px',
      background: `linear-gradient(to right, #4CAF50 ${percentage}%, #E5E7EB ${percentage}%)`,
      zIndex: 1,
    };
  };

  const lineStyle = getLineStyle();

  const getDottedLineColor = () => {
    if (trackingData?.['order-completed']) return '#4CAF50';
    if (trackingData?.['task-in-progress']) return '#4CAF50';
    if (trackingData?.['task-accepted']) return '#4CAF50';
    return '#E5E7EB';
  };

  const dottedLineStyle = {
    position: 'absolute',
    left: '24px',
    top: '16px',
    bottom: '0px',
    width: '2px',
    background: `repeating-linear-gradient(to bottom, ${getDottedLineColor()} 0, ${getDottedLineColor()} 4px, transparent 4px, transparent 8px)`,
  };
  const iconStyle = (isActive) => ({
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    backgroundColor: isActive ? '#4CAF50' : '#E5E7EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: isActive ? 'white' : '#9CA3AF',
  });

  const eventStyle = {
    marginBottom: '32px',
    position: 'relative',
    paddingLeft: '48px',
  };

  const eventDotStyle = {
    position: 'absolute',
    left: '20px',
    top: '16px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#4CAF50',
  };

  const formatDateTime = (timestamp) => {
    try {
      return format(parseISO(timestamp), 'dd MMM, yyyy hh:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return timestamp;
    }
  };

  return (
    <>
      <div style={overlayStyle} onClick={onClose} />
      <div style={drawerStyle}>
        <button style={closeButtonStyle} onClick={onClose}>
          <X size={20} />
        </button>

        <h2 style={{ marginBottom: '24px', fontSize: '24px', fontWeight: '600' }}>Track Blood Test</h2>

        <div style={timelineContainerStyle}>
          <div style={lineStyle} />
          <div style={iconContainerStyle}>
            <div style={iconStyle(true)}>
              <Calendar size={24} />
            </div>
            <div style={iconStyle(!!trackingData?.['task-accepted'])}>
              <Truck size={24} />
            </div>
            <div style={iconStyle(!!trackingData?.['task-in-progress'])}>
              <Briefcase size={24} />
            </div>
            <div style={iconStyle(!!trackingData?.['order-completed'])}>
              <FileText size={24} />
            </div>
          </div>

          <div style={{ position: 'relative', marginTop: '40px' }}>
            <div style={dottedLineStyle} />

            {/* Order Created */}
            <div style={eventStyle}>
              {trackingData?.['order-created'] && (
                <>
                  <div style={eventDotStyle} />

                  <h3 style={{ color: '#4CAF50', fontSize: '16px', fontWeight: '600', marginBottom: '4px' }}>
                    Test successfully scheduled
                  </h3>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {trackingData?.['order-created']?.appointment_time && (
                      <div style={{ color: '#6B7280', fontSize: '14px', marginTop: '4px' }}>
                        Test scheduled on {format(parseISO(trackingData?.['order-created']?.appointment_time), 'do')} of{' '}
                        {format(parseISO(trackingData?.['order-created']?.appointment_time), 'MMM')}
                      </div>
                    )}
                    <div style={{ color: '#6B7280', fontSize: '14px' }}>
                      {formatDateTime(trackingData?.['order-created']?.timestamp)}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Task Accepted */}
            {trackingData?.['task-accepted'] && (
              <div style={eventStyle}>
                <div style={eventDotStyle} />
                <h3 style={{ color: '#4CAF50', fontSize: '16px', fontWeight: '600', marginBottom: '4px' }}>
                  Phlebotomist assigned
                </h3>
                <p style={{ color: '#6B7280', marginBottom: '4px' }}>
                  {trackingData['task-accepted'].emedic_details?.emedic_name
                    ? `Phlebotomist ${trackingData['task-accepted'].emedic_details.emedic_name} assigned`
                    : 'Phlebotomist assigned'}
                </p>
                {trackingData['task-accepted'].emedic_details && (
                  <div
                    style={{
                      backgroundColor: '#FFF8F6',
                      padding: '12px',
                      borderRadius: '8px',
                      marginTop: '8px',
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: '#E5E7EB',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {trackingData['task-accepted'].emedic_details.emedic_name.charAt(0)}
                    </div>
                    <div>
                      <div style={{ fontWeight: '500' }}>
                        {trackingData['task-accepted'].emedic_details.emedic_name}
                      </div>
                      <div style={{ color: '#6B7280', fontSize: '14px' }}>
                        ID {trackingData['task-accepted'].emedic_details.emedic_number || 'N/A'}
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ color: '#6B7280', fontSize: '14px' }}>
                  {formatDateTime(trackingData['task-accepted'].timestamp)}
                </div>
              </div>
            )}

            {/* Task In Progress */}
            {trackingData?.['task-in-progress'] && (
              <div style={eventStyle}>
                <div style={eventDotStyle} />
                <h3 style={{ color: '#4CAF50', fontSize: '16px', fontWeight: '600', marginBottom: '4px' }}>
                  Sample collection in progress
                </h3>
                <p style={{ color: '#6B7280', marginBottom: '4px' }}>Phlebotomist is collecting samples</p>
                <div style={{ color: '#6B7280', fontSize: '14px' }}>
                  {formatDateTime(trackingData['task-in-progress'].timestamp)}
                </div>
              </div>
            )}

            {/* Order Completed */}
            {trackingData?.['order-completed'] && (
              <div style={eventStyle}>
                <div style={eventDotStyle} />
                <h3 style={{ color: '#4CAF50', fontSize: '16px', fontWeight: '600', marginBottom: '4px' }}>
                  Test completed
                </h3>
                <p style={{ color: '#6B7280', marginBottom: '4px' }}>Blood test completed successfully</p>
                <div style={{ color: '#6B7280', fontSize: '14px' }}>
                  {formatDateTime(trackingData['order-completed'].timestamp)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackBloodTestDrawer;
