import { useState, useEffect, useCallback } from 'react';
import auth_axios from 'networking/AuthAxios';
import { format } from 'date-fns';

/**
 * @typedef {Object} BloodTestReport
 * @property {number} id
 * @property {string} name
 * @property {string|null} date
 * @property {string|null} time
 * @property {string|null} address
 * @property {string|null} phone_number
 * @property {string|null} vendor_name
 * @property {string} status
 */

/**
 * @typedef {Object} BloodReportsResponse
 * @property {number} total_records
 * @property {number} total_pages
 * @property {number} current_page
 * @property {number} page_size
 * @property {number|null} next_page
 * @property {number|null} previous_page
 * @property {BloodTestReport[]} results
 */

/**
 * Hook to fetch patient blood reports with pagination
 * @param {Object} options
 * @param {number} options.currentPage - Initial page to fetch
 * @param {string} [options.status] - Filter by status
 * @param {string} [options.searchTerm] - Search term
 * @param {string} [options.fromDate] - Filter from date (YYYY-MM-DD)
 * @param {string} [options.toDate] - Filter to date (YYYY-MM-DD)
 * @returns {Object} Blood reports data and controls
 */
const usePatientBloodReports = ({ currentPage, status, searchTerm, start_date, end_date, vendor_id }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchReports = useCallback(
    async (page = 1) => {
      setLoading(true);
      setError(null);

      try {
        // Build query parameters
        const params = {
          page,
          page_size: 10, // Fixed page size
        };

        // Add optional filters if provided
        if (status) params.status = status;
        if (searchTerm) params.search_str = searchTerm;

        // REAL API IMPLEMENTATION
        // Add date and vendor filters if provided
        if (start_date) params.start_date = format(start_date, 'yyyy-MM-dd');
        if (end_date) params.end_date = format(end_date, 'yyyy-MM-dd');
        if (vendor_id) params.vendor_id = vendor_id;

        const response = await auth_axios.get('/patient_blood_report', { params });

        // Update state with response data
        setData(response?.data || []);

        return response.data;
      } catch (err) {
        console.error('Error fetching blood reports:', err);
        setError(err.message || 'Failed to fetch blood reports');

        // Use mock data in case of error (for development)
        const mockData = getMockData(page);
        setData(mockData);
      } finally {
        setLoading(false);
      }
    },
    [status, searchTerm, start_date, end_date, vendor_id]
  );

  // Fetch reports when dependencies change
  useEffect(() => {
    fetchReports(currentPage);
  }, [fetchReports, currentPage]);

  // Function to change page

  return {
    data,
    loading,
    error,
    refresh: () => fetchReports(currentPage),
  };
};

// Mock data for development/testing
const getMockData = (page = 1) => {
  return {
    total_records: 1372,
    total_pages: 138,
    current_page: page,
    page_size: 10,
    next_page: page < 138 ? page + 1 : null,
    previous_page: page > 1 ? page - 1 : null,
    results: [
      {
        id: 11403,
        name: 'Mahender Reddy',
        date: null,
        time: null,
        address: null,
        phone_number: '9849071999',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11402,
        name: 'Test 1',
        date: '2024-03-21',
        time: '14:30:00',
        address: null,
        phone_number: '9643243200',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11401,
        name: 'Mahender Reddy',
        date: null,
        time: null,
        address: null,
        phone_number: '9849071999',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11400,
        name: 'Mahender Reddy',
        date: null,
        time: null,
        address: null,
        phone_number: '9849071999',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11399,
        name: 'Mahender Reddy',
        date: null,
        time: null,
        address: null,
        phone_number: '9849071999',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11398,
        name: 'Praveen a',
        date: null,
        time: null,
        address: null,
        phone_number: '9849994462',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11397,
        name: 'Neerav Mittal',
        date: '2025-03-08',
        time: '06:45 AM',
        address: null,
        phone_number: '9035157540',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11396,
        name: 'Alpana Bose',
        date: null,
        time: null,
        address: null,
        phone_number: '8007306060',
        vendor_name: null,
        status: 'completed',
      },
      {
        id: 11395,
        name: 'Shreyas Murdeshwar',
        date: null,
        time: null,
        address: null,
        phone_number: '9833883197',
        vendor_name: null,
        status: 'main_booking',
      },
      {
        id: 11394,
        name: 'Rajesh Kamble',
        date: null,
        time: null,
        address: null,
        phone_number: '9004082401',
        vendor_name: null,
        status: 'main_booking',
      },
    ],
  };
};

export default usePatientBloodReports;
