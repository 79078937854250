import { useState, useCallback } from 'react';
import auth_axios from 'networking/AuthAxios';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {Object} ScheduleBloodTestParams
 * @property {string} provider
 * @property {string|number} userId
 * @property {string|number} addressId
 * @property {number} customerLatitude
 * @property {number} customerLongitude
 * @property {string} landmark
 * @property {Date} collectionDate
 * @property {string} collectionSlot
 * @property {string|number} customerAge
 * @property {string|number} testId
 * @property {('male'|'female'|'other')} gender
 * @property {string} pincode
 */

/**
 * @typedef {Object} ScheduleBloodTestResponse
 * @property {boolean} success
 * @property {string} message
 * @property {Object} data
 */

const useScheduleBloodTest = () => {
  const [isScheduling, setIsScheduling] = useState(false);
  const [error, setError] = useState(null);
  const [scheduledData, setScheduledData] = useState(null);

  const scheduleTest = useCallback(
    async ({
      provider,
      userId,
      addressId,
      customerLatitude,
      customerLongitude,
      landmark,
      collection_date,
      collection_slot,
      customerAge,
      testId,
      gender,
      pincode,
      slotId,
      vendor_id,
    }) => {
      setIsScheduling(true);
      setError(null);
      setScheduledData(null);

      try {
        // REAL API IMPLEMENTATION

        const response = await auth_axios.post('/patient_blood_report/', {
          provider,
          user_id: userId,
          address_id: addressId,
          customer_latitude: customerLatitude.toString(),
          customer_longitude: customerLongitude.toString(),
          landmark,
          collection_date: collection_date,
          collection_slot: collection_slot,
          slot_id: slotId,
          customer_age: customerAge?.toString(),
          test_id: testId?.toString(),
          gender: gender || 'Other',
          pincode,
          package_code: ['CAMP015'], // TODO: this should be done in the backend
          vendor_id: vendor_id,
        });

        setScheduledData(response.data);
        return response.data;
      } catch (err) {
        // const mockResponse = {
        // success: true,
        //   message: 'Blood test scheduled successfully',
        //   data: {
        //     appointment_id: 'BT' + Math.random().toString(36).substr(2, 9),
        //     scheduled_date: format(collectionDate, 'yyyy-MM-dd'),
        //     scheduled_time: collectionSlot,
        //     provider,
        //   },
        // };
        // setScheduledData(mockResponse);
        // Navigate to blood test details page after successful scheduling
        // navigate(`/blood-tests/${userId}/details`);
        // return mockResponse;
        throw err;
      } finally {
        setIsScheduling(false);
      }
    },
    []
  );

  const rescheduleTest = useCallback(async ({ collectionDate, collectionSlot, recordId, bookingId, slotId }) => {
    const dateTime = collectionDate;
    const finalDateTime = collectionSlot;
    try {
      const response = await auth_axios.patch(`/patient_blood_report/${recordId}/reschedule_report/`, {
        booking_id: bookingId,
        slot_datetime: finalDateTime,
        reason: 'Patient request',
        explanation: 'Patient prefers morning slot on different date',
        slot_id: slotId,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }, []);
  return {
    scheduleTest,
    isScheduling,
    error,
    scheduledData,
    rescheduleTest,
  };
};

export default useScheduleBloodTest;
