import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CancelBloodTestModal from './CancelBloodTestModal';
import EditBloodTestDrawer from './EditBloodTestDrawer';
import TrackBloodTestDrawer from './TrackBloodTestModal';
import { formatDateSafe, formatTimeSafe, getStatusColor } from './utils';
import { toast, ToastContainer } from 'react-toastify';
import auth_axios from 'networking/AuthAxios';
import { useNavigate } from 'react-router-dom';

const BloodTestDetailsTable = ({ patientData }) => {
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [showTrackDrawer, setShowTrackDrawer] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleCancelTest = async () => {
    try {
      setIsCancelling(true);
      await auth_axios.post(`patient_blood_report/${patientData?.id}/cancel_report/`, {
        reason: 'Patient requested',
        explanation: 'Patient requested',
      });
      toast.success('Blood test cancelled successfully');
      const t = setTimeout(() => {
        navigate('/blood-tests');
        clearTimeout(t);
      }, 500);
    } catch (error) {
      console.error('Error cancelling test:', error);
    } finally {
      setIsCancelling(false);
    }
  };

  const handleReschedule = () => {
    setShowEditDrawer(true);
  };

  const primaryButtonStyle = {
    ...buttonStyle,
    // border: 'none',
    // background: '#1a73e8',
    // color: 'white',
  };
  const navigateToBloodTest = () => {
    const t = setTimeout(() => {
      navigate(`/blood-tests`);
      clearTimeout(t);
    }, 500);
  };

  const handleFileUpload = async (file) => {
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('report_file', file);

      await auth_axios.post(`patient_blood_report/${patientData.id}/upload_dashboard_report/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Report uploaded successfully');
      const t = setTimeout(() => {
        navigate('/blood-tests');
        clearTimeout(t);
      }, 500);
    } catch (error) {
      console.error('Error uploading report:', error);
      toast.error(error?.response?.data?.message || 'Failed to upload report');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      handleFileUpload(file);
    } else {
      toast.error('Please upload a PDF file');
    }
    e.target.value = null; // Reset input
  };

  const columns = [
    {
      title: 'Vendor Name',
      render: (record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: '#e0e0e0',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
            {record?.vendor?.vendor_name?.charAt(0)}
          </div>
          {record?.vendor?.vendor_name}
        </div>
      ),
    },
    {
      title: 'Date',
      render: (record) => (record.appointment_date ? formatDateSafe(record.appointment_date) : ''),
    },
    {
      title: 'Time',
      render: (record) => (record.slot_time ? formatTimeSafe(record.slot_time) : ''),
    },
    {
      title: 'Status',
      render: (record) => (
        <span
          style={{
            padding: '4px 12px',
            borderRadius: '16px',
            fontSize: '12px',
            backgroundColor: getStatusColor(record.status).bg,
            color: getStatusColor(record.status).text,
          }}
        >
          {record.status}
        </span>
      ),
    },
    {
      title: 'Action',
      render: (record) => (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
          <div
            style={{ marginRight: '8px', textAlign: 'left', color: '#243B86', fontSize: '14px', fontWeight: '600' }}
            onClick={handleReschedule}
          >
            Reschedule
          </div>

          <div
            style={{ textAlign: 'left', color: '#243B86', fontSize: '14px', fontWeight: '600', marginLeft: '8px' }}
            onClick={() => setShowCancelModal(true)}
          >
            Cancel Test
          </div>
        </div>
      ),
    },
  ];
  const isVendorOrangeHealth = patientData?.vendor?.vendor_name?.toLowerCase() === 'orange health';
  const isRedcliffe = patientData?.vendor?.vendor_name?.toLowerCase() === 'redcliffe';
  const isCustomVendor = !isVendorOrangeHealth && !isRedcliffe;
  const hasReport = !!patientData?.report;
  const isCancelled = patientData?.status?.toLowerCase() === 'cancelled';
  // const isCustomVendor = true;
  return (
    <>
      <div
        style={{
          background: 'white',
          borderRadius: '8px',
          padding: '24px',
        }}
      >
        {isCustomVendor && !hasReport && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <input
              type="file"
              id="report-upload"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              disabled={isUploading}
            />
            <button
              style={{
                ...buttonStyle,
                border: '1px solid #243B86',
                borderRadius: '8px',
                marginRight: '8px',
                marginBottom: '8px',
                opacity: isUploading ? 0.7 : 1,
                cursor: isUploading ? 'not-allowed' : 'pointer',
              }}
              onClick={() => document.getElementById('report-upload').click()}
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Upload Report'}
            </button>
          </div>
        )}
        {!isCustomVendor && !isCancelled && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              style={{
                ...buttonStyle,
                border: '1px solid #243B86',
                borderRadius: '8px',
                marginRight: '8px',
                marginBottom: '8px',
              }}
              onClick={() => setShowTrackDrawer(true)}
            >
              Track Test
            </button>
          </div>
        )}
        <TableContainer
          component={Paper}
          className="overflow-x-auto"
          elevation={0}
          sx={{ b: 'white', border: '1px solid #d9d9d9' }}
        >
          <Table className="min-w-full">
            <TableHead>
              <TableRow className="bg-gray-100">
                {columns.map((column, index) => {
                  if (isCustomVendor && column.title === 'Action') {
                    return null;
                  }

                  if (patientData?.status?.toLowerCase() === 'cancelled' && column.title === 'Action') {
                    return null;
                  }
                  return (
                    <TableCell key={index} align={column.align} style={{ fontWeight: '600' }}>
                      {column.title}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                className="hover:bg-gray-100 cursor-pointer transition-colors duration-150"
                style={{ transition: 'background-color 0.15s ease-in-out' }}
              >
                {columns.map((column, index) => {
                  if (isCustomVendor && column.title === 'Action') {
                    return null;
                  }

                  if (patientData?.status?.toLowerCase() === 'cancelled' && column.title === 'Action') {
                    return null;
                  }
                  return (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      x
                    >
                      {column.render(patientData)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <CancelBloodTestModal
        open={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleCancelTest}
        isLoading={isCancelling}
      />

      <EditBloodTestDrawer
        open={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
        patientData={patientData}
        callback={navigateToBloodTest}
      />

      <TrackBloodTestDrawer
        open={showTrackDrawer}
        onClose={() => setShowTrackDrawer(false)}
        trackingData={patientData?.events}
      />
    </>
  );
};

export default BloodTestDetailsTable;

const buttonStyle = {
  padding: '8px 16px',
  borderRadius: '4px',
  fontSize: '14px',
  cursor: 'pointer',
  fontWeight: '600',
  // border: '1px solid #ddd',
  background: 'transparent',
  color: '#243B86',
};
