import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import { addDays, format } from 'date-fns';
const BloodTestFilterDrawer = ({ open, onClose, onApply, initialFilters, vendors, isLoadingVendors, vendorsError }) => {
  const today = new Date();

  const [startDate, setStartDate] = useState(today);
  const [selectedDateOption, setSelectedDateOption] = useState('today');
  const [selectedVendorId, setSelectedVendorId] = useState('');
  const getEndDate = () => {
    if (selectedDateOption === 'today') return today;
    if (selectedDateOption === 'tomorrow') return addDays(today, 1);
    if (selectedDateOption === '7days') return addDays(today, 7);
  };
  //add orange health and redcliffe to vendor list

  const endDate = getEndDate();
  const handleClearAll = () => {
    setStartDate(null);
    setSelectedVendorId('');
  };

  const handleApplyFilter = () => {
    onApply({
      startDate,
      endDate,
      vendorId: selectedVendorId,
    });
  };

  if (!open) return null;

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const drawerStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    width: '400px',
    backgroundColor: 'white',
    boxShadow: '-2px 0 4px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    zIndex: 1001,
    overflowY: 'auto',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '16px',
    right: '16px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f3f4f6',
  };

  const sectionTitleStyle = {
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '16px',
    color: '#111827',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px 12px',
    borderRadius: '8px',
    border: '1px solid #E5E7EB',
    fontSize: '14px',
    marginBottom: '16px',
  };

  const radioGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  };

  const radioLabelStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  };

  const radioStyle = {
    accentColor: '#243B86',
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '12px',
    marginTop: '24px',
    paddingTop: '16px',
    borderTop: '1px solid #E0E0E0',
  };

  const buttonStyle = (isPrimary = false) => ({
    flex: 1,
    padding: '8px 16px',
    borderRadius: '8px',
    border: isPrimary ? 'none' : '1px solid #243B86',
    backgroundColor: isPrimary ? '#243B86' : 'white',
    color: isPrimary ? 'white' : '#243B86',
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
    textTransform: 'none',
  });

  return (
    <>
      <div style={overlayStyle} onClick={onClose} />
      <div style={drawerStyle}>
        <button style={closeButtonStyle} onClick={onClose}>
          <X size={20} />
        </button>

        <h2 style={{ fontSize: '20px', fontWeight: '600', marginBottom: '24px' }}>Filters</h2>

        {/* Date Filter */}
        <div style={{ marginBottom: '32px' }}>
          <h3 style={sectionTitleStyle}>By Date</h3>
          <input
            type="date"
            style={inputStyle}
            value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
            onChange={(e) => setStartDate(e.target.value ? new Date(e.target.value) : null)}
            max={format(today, 'yyyy-MM-dd')}
          />
          <div style={radioGroupStyle}>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                name="dateOption"
                value="today"
                checked={selectedDateOption === 'today'}
                onChange={(e) => setSelectedDateOption(e.target.value)}
                style={radioStyle}
              />
              Today
            </label>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                name="dateOption"
                value="tomorrow"
                checked={selectedDateOption === 'tomorrow'}
                onChange={(e) => setSelectedDateOption(e.target.value)}
                style={radioStyle}
              />
              Tomorrow
            </label>
            <label style={radioLabelStyle}>
              <input
                type="radio"
                name="dateOption"
                value="7days"
                checked={selectedDateOption === '7days'}
                onChange={(e) => setSelectedDateOption(e.target.value)}
                style={radioStyle}
              />
              7 days
            </label>
          </div>
        </div>

        {/* Vendor Name Filter */}
        <div>
          <h3 style={sectionTitleStyle}>By Vendor Name</h3>
          <div style={{ marginTop: '16px' }}>
            {isLoadingVendors ? (
              <div
                style={{
                  padding: '12px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  marginBottom: '16px',
                  textAlign: 'center',
                  color: '#666',
                }}
              >
                Loading vendors...
              </div>
            ) : vendorsError ? (
              <div
                style={{
                  padding: '12px',
                  backgroundColor: '#ffebee',
                  borderRadius: '8px',
                  marginBottom: '16px',
                  color: '#d32f2f',
                }}
              >
                {vendorsError}
              </div>
            ) : (
              <select
                value={selectedVendorId}
                onChange={(e) => setSelectedVendorId(e.target.value)}
                style={{
                  width: '100%',
                  padding: '12px',
                  border: '1px solid #E5E7EB',
                  borderRadius: '8px',
                  fontSize: '14px',
                  backgroundColor: 'white',
                  marginBottom: '16px',
                }}
              >
                <option value="">Select a vendor</option>
                {vendors?.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.vendor_name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        {/* Footer Actions */}
        <div style={buttonContainerStyle}>
          <button style={buttonStyle(false)} onClick={handleClearAll}>
            Clear All
          </button>
          <button style={buttonStyle(true)} onClick={handleApplyFilter}>
            Apply Filter
          </button>
        </div>
      </div>
    </>
  );
};

BloodTestFilterDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  initialFilters: PropTypes.shape({
    dateOption: PropTypes.string,
    dateRange: PropTypes.instanceOf(Date),
    vendorName: PropTypes.string,
  }),
};

export default BloodTestFilterDrawer;
