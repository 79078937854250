import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

const CancelBloodTestModal = ({ open, onClose, onConfirm, isLoading }) => {
  if (!open) return null;

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalStyle = {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '24px',
    width: '400px',
    position: 'relative',
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: '24px',
    top: '24px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '4px',
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '12px',
    textAlign: 'center',
  };

  const messageStyle = {
    color: '#666',
    textAlign: 'center',
    marginBottom: '24px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
  };

  const buttonStyle = {
    padding: '14px 32px',
    borderRadius: '12px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#243B86',
    color: 'white',
  };

  return (
    <div style={overlayStyle} onClick={onClose}>
      <div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <button style={closeButtonStyle} onClick={onClose}>
          <CloseIcon />
        </button>
        <div style={titleStyle}>Are You Sure</div>
        <div style={messageStyle}>This action cannot be undone</div>
        <div style={buttonContainerStyle}>
          <button style={cancelButtonStyle} onClick={onConfirm} disabled={isLoading}>
            {isLoading ? 'Cancelling...' : 'Cancel Test'}
          </button>
        </div>
      </div>
    </div>
  );
};

CancelBloodTestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CancelBloodTestModal;
