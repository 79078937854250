import { useState, useEffect } from 'react';
import { getCoordinatesFromAddress } from '../components/Blood-test/utils';
import { toast } from 'react-toastify';

/**
 * Custom hook to get coordinates from an address
 * @param {Object} address - The address object containing address details
 * @returns {Object} Object containing coordinates data, loading state, and error
 */
const useAddressCoordinates = (address) => {
  const [coordinates, setCoordinates] = useState({
    latitude: '',
    longitude: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (!address) return;

      setIsLoading(true);
      setError(null);

      try {
        const coords = await getCoordinatesFromAddress(address);
        setCoordinates(coords);
      } catch (err) {
        console.error('Error getting coordinates:', err);
        setError(err.message || 'Failed to get location coordinates');
        toast.error('Failed to get location coordinates. Using default values.');
        setCoordinates({
          latitude: '',
          longitude: '',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoordinates();
  }, [address]);

  return {
    coordinates,
    isLoading,
    error,
    setCoordinates, // Expose setter for manual updates
  };
};

export default useAddressCoordinates;
